import React, {useState, useEffect} from "react"
import {useParams} from "react-router-dom";

import OrganizationApi from "../../services/organizations";
import LocationApi from "../../services/locations";

import LocationsTable from "../../tables/LocationsTable"
import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav"
import OrganizationLocationHeaderNav from "../../fragments/nav/OrganizationLocationHeaderNav"

import Page from "../Page"
import Loading from "../../fragments/Loading"

import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

function OrganizationLocationsPage(props) {
    const [locations, setLocations] = useState([]);
    const [organization, setOrganization] = useState();
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);

    const locationApi = new LocationApi(props.token);
    const organizationApi = new OrganizationApi(props.token);
    const routeParams = useParams();
    const { organizationId } = routeParams;

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
        fetch();
    },[]);

    const fetch = () => {
        locationApi.getByOrganizationId(organizationId).then((response) => {
            setLocations(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    }

    const onDelete = (id, name) => {
        locationApi.delete(id).then((response) => {
            setMessage(`Location "${name}" has been deleted`)
            setError("")
            fetch();
        })
        .catch((err) => {
            console.error(err.message);
            setError(err.message)
            setMessage("")
        });
    }

    const handleOpenCreateLocationtPage =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}/location/new`
        }else{
            return `/organization/${organizationId}/location/new`
        }
    }

    if(!organization) {
        return (<Loading />)
    }
    return(
        <Page 
            buttonText="Create Location"
            buttonUrl={handleOpenCreateLocationtPage()}
            header={`Organization: ${organization.name}`}
        >
            { routeParams.countryId && routeParams.stateId && routeParams.municipalityId && routeParams.organizationId ? 
                <OrganizationLocationHeaderNav countryId={routeParams.countryId} stateId={routeParams.stateId} municipalityId={routeParams.municipalityId} organizationId={routeParams.organizationId}/>
                :
                <OrganizationHeaderNav organizationId={organizationId}/>
            }
            <Error message={error} />
            <Message message={message} />
            <LocationsTable
                locations={locations}
                organization={organization}
                onDelete={onDelete}
                {...props} />
        </Page>
    )
}

export default OrganizationLocationsPage;