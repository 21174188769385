import axios from "axios";

const {REACT_APP_API_URI} = process.env;

const BaseUrl = `${REACT_APP_API_URI}/slide`


export function getSlides() {
    try{

        return axios.get(`${BaseUrl}`)
    }catch(error) {
        return[]
    }
}

export function getSlide(id) {
    try{
        return axios.get(`${BaseUrl}/${id}`)
    }catch(error) {
        return[]
    }
}

export function updateSlide(id,body) {
    try{
        return axios.patch(`${BaseUrl}/${id}`,body)
    }catch(error) {
        return[]
    }
}

export function createSlide(body,token) {
    try{
        return axios.post(`${BaseUrl}`,body,{
            headers: { 'Authorization': 'Bearer ' + token }
        })
    }catch(error) {
        return[]
    }
}

export function deleteSlide(id,token) {
    try{
        return axios.delete(`${BaseUrl}/${id}`,{
            headers: { 'Authorization': 'Bearer ' + token }
        })
    }catch(error) {
        return[]
    }
}