import {useEffect,useState} from 'react';
import {useLocation} from "react-router-dom";
import DashboardHeader from '../../components/DashboardHeader';
import {getBlogpost} from "../../services/blogposts";
import '../styles.css';
import Blogform from "./form";

function SingleBlogPost(props) {
    const  location = useLocation();
    const { id } = location.state;

    const [data, setData] = useState('');

    useEffect(() => {
        getBlogpost(id).then((response) => {
            setData(response.data);
        })
            .catch((err) => {
                console.log(err.message);
            });
    },[]);
    if (!data) return null;

    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="Back" link="/blog" />

            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Edit Blog Posts : {data.data.title}  </h2>



                </div>
                <Blogform token={props.token} blog={data.data}/>
            </div>
        </div>
    );
}

export default SingleBlogPost;