
import FormGroup from "./FormGroup"
import {useState, useEffect} from 'react';

const TextAreaField = (props) => {
    const [data, setData] = useState(props.value);

    const onChange = (e) => {
        setData(e.target.value);
        props.onChange(props.id, e.target.value);
    }

    useEffect(() => {
        if(props.onLoad) {
            props.onLoad();
        }
    },[])

    return (
        <FormGroup {...props}>
            <textarea required className="form-control"
                type="text"
                name={props.id}
                id={props.id}
                onChange={onChange}
                rows={props.rows || 25}
                cols={props.cols || 100}
                value={data}
            />
        </FormGroup>
    )
}

export default TextAreaField