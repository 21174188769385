import React, {useState} from 'react';
import OrganizationApi from "../services/organizations";
import Error from "../fragments/form/Error"
import Message from "../fragments/form/Message"

import TextValue from "../fragments/table/TextValue"
import ItemActions from "../fragments/table/ItemActions"

function OrganizationsTable (props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const organizationApi = new OrganizationApi(props.token);

    function deleteOrganization(id, name){
        organizationApi.delete(id).then((response) => {
            setMessage(`Organization "${name}" has been deleted`)
            setError("");
            props.refresh();
        })
        .catch((err) => {
            console.error(err.message);
            setError(err.message);
            setMessage("");
        });
    }

    return(
        <div>
            <Error message={error} />
            <Message message={message} />
            <table>
                <tbody>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>STATUS</th>
                        <th>ACTIONS</th>
                    </tr>

                    {props.organizations.map((organization, index) => (
                        <tr key={index}>
                            <td><TextValue value={organization.id} /></td>
                            <td><TextValue value={organization.name} /></td>
                            <td><TextValue value={organization.is_published ? 'Published': 'Pending'} /></td>
                            <td><ItemActions
                                    onConfirm={() => deleteOrganization(organization.id, organization.name)}
                                    editUrl={`/organization/${organization.id}/edit`}
                                    viewUrl={`/organization/${organization.id}`}
                                    itemName={`organization "${organization.name}"`}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default OrganizationsTable;