import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PaginationComponent = ({ 
    totalPages, 
    pageItemActive, 
    handleSelectItem,
    maxPagesToShow = 10
}) => {
   
    const getPaginationItems = () => {
        const items = [];
        const startPage = Math.max(1, pageItemActive - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    
        if (startPage > 1) {
            items.push(
                <Pagination.Item key={1} active={1 === pageItemActive} onClick={() => handleSelectItem(1)}>
                    1
                </Pagination.Item>
            );
            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="start-ellipsis" disabled />);
            }
        }
    
        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item key={number} active={number === pageItemActive} onClick={() => handleSelectItem(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" disabled />);
            }
            items.push(
                <Pagination.Item key={totalPages} active={totalPages === pageItemActive} onClick={() => handleSelectItem(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }
    
        return items;
    };

    const previousPage = ()=>{
        let newPageNumber = pageItemActive > 1 ? pageItemActive - 1 : pageItemActive
        handleSelectItem(newPageNumber);
    }

    const nextPage = ()=>{
        let newPageNumber = pageItemActive < totalPages ? pageItemActive + 1 : totalPages
        handleSelectItem(newPageNumber);
    }

    return (
        <>
            {
                totalPages > 1 && <Pagination size="sm" className="events-pagination-wrap">
                    <Pagination.Prev
                        onClick={() => previousPage()}
                        disabled={pageItemActive === 1}
                    />
                    {getPaginationItems()}
                    <Pagination.Next
                        onClick={() => nextPage()}
                        disabled={pageItemActive === totalPages}
                    />
                </Pagination>
            }
        </>
    );
};

export default PaginationComponent;
