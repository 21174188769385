
import {useParams, useNavigate} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import Page from "../Page";
import CreateStateForm from "../../forms/MunicipleHierarchy/CreateStateForm"
import StateApi from "../../services/states";
import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

function CreateStatePage(props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const stateApi = new StateApi(props.token);
    const navigate = useNavigate();
    const {countryId} = useParams()

    const onSubmit = (formData) => {
        stateApi.create(formData).then((response) => {
            navigate(`/country/${countryId}/states`)
        })
        .catch((err) => {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        });
    }


    return (
        <Page
            buttonText="Back"
            buttonUrl={`/country/${countryId}/states`}
            header={`Create Province/State`}
        >
            <Error error={error} />
            <Message message={message} />
            <CreateStateForm
                {...props}
                countryId={countryId}
                onSubmit={onSubmit}
                />
        </Page>
    );
}

export default CreateStatePage;