import React, {useState} from "react"
import {createSponsor} from "../../services/sponsors";


function CreateSponsorform(props){
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);


    const [response, setResponse] = useState({
        "name": "",
        "image_url": "",
        "sponsor_url": "",
        "sponsor_type": "",
    })
    const handleOnChange = e => {

        setResponse({...response, [e.target.name]: e.target.value})
    }

    function createSingleSponsor(body){
        createSponsor(body,props.token).then((response) => {
            setData(response.data);
        })
            .catch((err) => {
                console.log(err.message);
                setError(err.message)
            });
    }

    const handleOnSubmit = e => {
        e.preventDefault();
        createSingleSponsor({
            "name": response.name,
            "image_url": response.image_url,
            "sponsor_url": response.sponsor_url,
            "sponsor_type": response.sponsor_type,
        })
    }


    return(
        <div className="card-body">
            {error ? <div className="alert alert-danger" role="alert">
                {error}
            </div>: null }
            {data ? <div className="alert alert-success" role="alert">
                Sponsor with ID {data.data.sponsor.id} has been created
            </div>: null }


            <form onSubmit={handleOnSubmit}>
                <div className="col-md-4 mb-3">
                    <label htmlFor="name">Name</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="name"
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label htmlFor="image_url">Image Url</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="image_url"
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label htmlFor="sponsor_url">Sponsor Url</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="sponsor_url"
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label htmlFor="sponsor_type">Sponsor Type</label>
                    <div className="form-group">
                        <select required className="form-select"
                                name="sponsor_type"
                                onChange={handleOnChange}
                        >
                            <option>Select Type</option>
                            <option value="GOLD">Gold Sponsor</option>
                            <option value="SILVER">Silver Sponsor</option>
                            <option value="THANK">Special Thank you</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-4 mb-3">

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary" >Submit</button>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default CreateSponsorform;