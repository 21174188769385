import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import MunicipalityApi from "../../services/municipalities";
import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"
import Loading from "../../fragments/Loading";
import EditMunicipalityForm from "../../forms/MunicipleHierarchy/EditMunicipalityForm";
import Page from "../Page";

const EditMunicipalityPage = (props) => {
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [municipality, setMunicipality] = useState();

    const municipalityApi = new MunicipalityApi(props.token);
    const { countryId, stateId, municipalityId } = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        municipalityApi.getById(municipalityId).then((response) => {
            setMunicipality(response)
        })
    }, [municipalityId]);
   
    const onSubmit = (formData) => {
        municipalityApi.update(municipalityId, formData).then((response) => {
            navigate(`/country/${countryId}/state/${stateId}/municipalities`);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        });
    }

    if(!municipality) {
        return (<Loading />)
    }
    return (
        <Page
            buttonText="Back"
            buttonUrl={`/country/${countryId}/state/${stateId}/municipalities`}
            header={`Edit Municipality: ${municipality.city}`}
        >
            <Error message={error} />
            <Message message={message} />
            <EditMunicipalityForm 
                {...props}
                municipality={municipality}
                onSubmit={onSubmit} />
        </Page>

    );
}

export default EditMunicipalityPage;
