import {useEffect,useState} from 'react';
import {useLocation} from "react-router-dom";
import DashboardHeader from '../../components/DashboardHeader';
import '../styles.css';
import {getSponsor} from "../../services/sponsors";
import EditSponsorform from "./edit-form";

function SingleSponsor(props) {
    const  location = useLocation();
    const { id } = location.state;

    const [data, setData] = useState('');

    useEffect(() => {
        getSponsor(id).then((response) => {
            setData(response.data);
        })
            .catch((err) => {
                console.log(err.message);
            });
    },[]);
    if (!data) return null;


    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="Back" link="/sponsors" />

            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Edit Sponsor : {data.data.name}  </h2>



                </div>
                <EditSponsorform token={props.token} sponsor={data.data}/>
            </div>
        </div>
    );
}

export default SingleSponsor;