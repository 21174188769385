import React, {useState, useEffect} from "react"
import {useParams} from "react-router-dom";

import OrganizationApi from "../../services/organizations";
import EventsApi from "../../services/events";

import EventsTable from "../../tables/EventsTable"
import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav"
import OrganizationLocationHeaderNav from "../../fragments/nav/OrganizationLocationHeaderNav"

import Page from "../Page"
import Loading from "../../fragments/Loading";
import Pagination from "../../components/Pagination"


function OrganizationEventsPage(props) {
    const [events, setEvents] = useState();
    const [organization, setOrganization] = useState();
    const [totalPages, setTotalPages] = useState(0);
    const [pageItemActive, setPageItemActive] = useState(1);

    const eventsApi = new EventsApi(props.token);
    const organizationApi = new OrganizationApi(props.token);
    const routeParams = useParams();
    const { organizationId } = routeParams

    const fetchEvents = (organizationId, pageItemActive) => {
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            eventsApi.getPublishedEvents(organizationId, pageItemActive).then((response) => {
                setEvents(response.events);
                setTotalPages(response.total_pages)
            })
            .catch((err) => {
                console.error(err.message, err.stack);
            });
        }else{
            eventsApi.getByOrganizationIdPerPage(organizationId, pageItemActive).then((response) => {
                setEvents(response.events);
                setTotalPages(response.total_pages)
            })
            .catch((err) => {
                console.error(err.message, err.stack);
            });
        }
       
    }

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
        fetchEvents(organizationId, pageItemActive);
    },[organizationId, pageItemActive]);

 
    const handleSelectPage = (pageNumber )=>{
        setPageItemActive(pageNumber);
    }

    const handleOpenCreateEventPage =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}/event/new`
        }else{
            return `/organization/${organizationId}/event/new`
        }
    }

    if(!events || !organization) {
        return (<Loading />)
    }

    return(
        <Page 
            buttonText="Create Event"
            buttonUrl={handleOpenCreateEventPage()}
            header={`Events for the organization: ${organization.name}`}
        >
           { routeParams.countryId && routeParams.stateId && routeParams.municipalityId && routeParams.organizationId ? 
                <OrganizationLocationHeaderNav countryId={routeParams.countryId} stateId={routeParams.stateId} municipalityId={routeParams.municipalityId} organizationId={routeParams.organizationId} isShowArchive={true}/>
                :
                <OrganizationHeaderNav organizationId={organizationId} isShowArchive={true}/>
           }
            <EventsTable
                events={events}
                organization={organization}
                refresh={() => fetchEvents(organizationId, pageItemActive)} 
                {...props} />
            <Pagination totalPages={totalPages} pageItemActive={pageItemActive} handleSelectItem={handleSelectPage}/>
        </Page>
    )
}

export default OrganizationEventsPage;