import BoolValue from "../fragments/table/BoolValue"
import TextValue from "../fragments/table/TextValue"
import ItemActions from "../fragments/table/ItemActions"
import {useParams} from "react-router-dom";

const LocationsTable = (props) => {
    const routeParams = useParams();
    const onDelete = (id, name) => {
        props.onDelete(id, name);
    }
    return (
        <table>
            <tbody>
                <tr>
                    <th>ID</th>
                    <th>PUBLISHED</th>
                    <th>NAME</th>
                    <th>ADDRESS 1</th>
                    <th>ADDRESS 2</th>
                    <th>CITY/STATE</th>
                    <th>COUNTRY</th>
                    <th>ZIP</th>
                    <th>ACTIONS</th>
                </tr>

                {props.locations.map((location, index) => (
                    <tr key={index}>
                        <td><span>{location.id}</span></td>
                        <td><BoolValue value={location.is_published} /></td>
                        <td><TextValue value={location.name} /></td>
                        <td><TextValue value={location.address_1} /></td>
                        <td><TextValue value={location.address_2} /></td>
                        <td><TextValue value={`${location.city} / ${location.state}`} /></td>
                        <td><TextValue value={location.country} /></td>
                        <td><TextValue value={location.zip} /></td>
                        <td>
                            {routeParams.countryId && routeParams.stateId && routeParams.municipalityId && routeParams.organizationId ?
                                <ItemActions
                                    onConfirm={() => onDelete(location.id, location.name)}
                                    editUrl={`/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${routeParams.organizationId}/location/${location.id}/edit`}
                                    viewUrl={`/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${routeParams.organizationId}/location/${location.id}`}
                                    itemName={`location "${location.name}"`}
                                />
                                :
                                <ItemActions
                                    onConfirm={() => onDelete(location.id, location.name)}
                                    editUrl={`/organization/${props.organization.id}/location/${location.id}/edit`}
                                    viewUrl={`/organization/${props.organization.id}/location/${location.id}`}
                                    itemName={`location "${location.name}"`}
                                />
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default LocationsTable;