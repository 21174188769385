import React, {useState, useEffect} from "react"
import {useParams} from "react-router-dom";

import OrganizationApi from "../../services/organizations";
import LocationApi from "../../services/locations";
import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav"
import OrganizationLocationHeaderNav from "../../fragments/nav/OrganizationLocationHeaderNav"
import LocationPanel from "../../panels/LocationPanel"

import Page from "../Page"
import Loading from "../../fragments/Loading"

function OrganizationLocationPage(props) {
    const [location, setLocation] = useState();
    const [organization, setOrganization] = useState();

    const locationApi = new LocationApi(props.token);
    const organizationApi = new OrganizationApi(props.token);

    const routeParams = useParams();
    const { organizationId, locationId } = routeParams

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
        locationApi.getById(locationId).then((response) => {
            setLocation(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    },[]);

    const handleBack =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${routeParams.organizationId}/locations`
        }else{
            return `/organization/${organizationId}/locations`
        }
    }

    if(!organization || !location) {
        return (<Loading />)
    }
    return(
        <Page 
            buttonText="Back"
            buttonUrl={handleBack()}
            header={`Viewing location "${location.name}" for organization: ${organization.name}`}
        >
            {routeParams.countryId && routeParams.stateId && routeParams.municipalityId && routeParams.organizationId ?
                <OrganizationLocationHeaderNav countryId={routeParams.countryId} stateId={routeParams.stateId} municipalityId={routeParams.municipalityId} organizationId={routeParams.organizationId} />
                :
                <OrganizationHeaderNav organizationId={organizationId} />
            }
            <LocationPanel
                location={location}
                organization={organization}
            />
        </Page>
    )
}

export default OrganizationLocationPage;