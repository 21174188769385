import React, {useState, useEffect} from "react"
import EventApi from "../../services/events";
import {useParams} from "react-router-dom";

import Page from "../Page"
import EventPanel from "../../panels/EventPanel"
import Loading from "../../fragments/Loading"
import EventParticipantsHeaderNav from "../../fragments/nav/SponsorshipsHeaderNav"
import EventLocationHeaderNav from "../../fragments/nav/EventLocationHeader"


function OrganizationEventPage(props) {
    const [event, setEvent] = useState(null);
    const eventApi = new EventApi(props.token);
    const routeParams = useParams();
    const { organizationId, eventId } = routeParams

    useEffect(() => {
        eventApi.getById(eventId).then((response) => {
            setEvent(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    },[]);

    const handleBack =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}/events`
        }else{
            return `/organization/${organizationId}/events`
        }
    }

    if(!event) {
        return (<Loading />)
    }
    return(
        <Page 
            buttonText="Back"
            buttonUrl={handleBack()}
            header={`Event: ${event.title}`}
        >
            {routeParams.countryId && routeParams.stateId && routeParams.municipalityId && routeParams.organizationId ?
                <EventLocationHeaderNav countryId={routeParams.countryId} stateId={routeParams.stateId} municipalityId={routeParams.municipalityId} organizationId={routeParams.organizationId} eventId={routeParams.eventId}/>
                :
                <EventParticipantsHeaderNav organizationId={organizationId} eventId={eventId} />
            }
            <EventPanel event={event} {...props} />
        </Page>
    )
}

export default OrganizationEventPage;