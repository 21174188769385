import { GoogleLogin } from '@react-oauth/google';
import { gapi } from 'gapi-script';
import React, { useEffect } from 'react';



function authGoogle(token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({'token': token})
    };

    return fetch(`${process.env.REACT_APP_API_URI}/admin/auth/google`, requestOptions)
        .then(response => response.json())
}


function GoogleAuth(props) {
    const onSuccess = (res) => {
        //console.log(res)
        authGoogle(res.credential).then((authRes) => {
            props.onAuthSuccess(authRes.data.token);
        })
    };
    const onFail = (err) => {
        props.onAuthFail(err);
    };
    return (<>
    <div className="login-page bg-light">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 offset-lg-3">
                    <h3 className="mb-3">LOADSTONE ADMIN</h3>
                    <div className="bg-white shadow rounded">
                        <div className="row">
                            <div className="col-md-7 pe-0">
                                <div className="form-left h-100 py-5 px-5">

                                    <GoogleLogin
                                        buttonText="Sign in with Google"
                                        onSuccess={onSuccess}
                                        onFailure={onFail}
                                        cookiePolicy={'single_host_origin'}
                                        isSignedIn={true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-5 ps-0 d-none d-md-block">
                                <div className="form-right h-100 text-white text-center ">
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/logo.png"}
                                        alt=""
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="text-end text-secondary mt-3">LOADSTONE Copyright 2023</p>
                </div>
            </div>
        </div>
    </div>
</>
    );
}

export default GoogleAuth;
