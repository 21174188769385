import {Link} from "react-router-dom";

const HeaderNavItem = (props) => (
    <div className="header-nav-item">
        <Link to={props.location}>
            {props.label}
        </Link>
    </div>
)

export default HeaderNavItem;