import React, { useState } from "react"
import CategoryApi from "../../services/categories";

function CreateCategoryform(props) {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const categoryApi = new CategoryApi(props.token);

    const [formData, setFormData] = useState({
        "name": null,
        "is_event": false,
        "is_organization": false,
        "icon_file": null,
    })

    const onValueChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onCheckChange = e => {
        setFormData({ ...formData, [e.target.name]: !formData[e.target.name] })
    }

    const onFileChange = e => {
        setFormData({ ...formData, "icon_file": e.target.files[0]});
    }

    function createSingleCategory(body) {
        if(!formData.is_event && !formData.is_organization){
            setError("It should be event or organization category or both");
            return;
        }
        categoryApi.create(body).then((formData) => {
            setSuccess(true);
        })
            .catch((err) => {
                let response = err.response.data;

                if(response?.data?.message){
                    setError(response.data.message);
                }else{
                    setError(err.message);
                }
            });
    }

    const handleOnSubmit = e => {
        e.preventDefault();
        createSingleCategory(formData)
    }


    return (
        <div className="card-body">
            {error ? <div className="alert alert-danger alert-dismissible fade show" role="alert" id="alert">
                <strong>{error}</strong>
            </div> : null}
            {success ? <div className="alert alert-success alert-dismissible fade show" role="alert" id="alert">
                <strong>Category has been created</strong>
            </div> : null}


            <form onSubmit={handleOnSubmit}>
                <div className="col-md-4 mb-3">
                    <div className="form-group mb-2">
                        <label htmlFor="name">Name</label>
                        <input required className="form-control"
                            type="text"
                            name="name"
                            id="name"
                            onChange={onValueChange}
                        />
                    </div>
                    <div className="form-group mb-2">
                        <input className="form-check-input"
                            type="checkbox"
                            name="is_event"
                            id="is_event"
                            checked={formData.is_event}
                            onChange={onCheckChange}
                        />
                        <label htmlFor="is_event" className="ms-1">Event</label>
                    </div>
                    <div className="form-group mb-2">
                        <input className="form-check-input"
                            type="checkbox"
                            name="is_organization"
                            id="is_organization"
                            checked={formData.is_organization}
                            onChange={onCheckChange}
                        />
                        <label htmlFor="is_organization" className="ms-1">Organization</label>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="file-upload-svg">Upload Svg</label>
                        <input required className="form-control"
                            type="file"
                            name="file-upload-svg"
                            id="file-upload-svg"
                            onChange={onFileChange}
                            accept=".svg"
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary" >Submit</button>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default CreateCategoryform;