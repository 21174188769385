
import {useState} from 'react';
import DropdownSelector from "../fragments/form/DropdownSelector"
import SubmitButton from "../fragments/form/SubmitButton"
import SearchSelector from '../components/SearchSelector/ParticipantSelect';


function CreateEventParticipantForm(props) {
    const [formData, setFormData] = useState(new FormData());

    const onChange = (id, value) => {
        let formatValue = parseInt(value)
        formData.set(id, formatValue);
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(formData)
    }
  
    return (
        <form onSubmit={onSubmit}>
            <SearchSelector id="participant_id" required={true} handleSearch={props.handleSearchParticipant} collection={props.organizations} onChange={onChange} label="Organization" prompt="Please select a participant"/>
            <DropdownSelector id="participant_role_id" required={true} collection={props.participantRoles} onChange={onChange} label="Role" prompt="Please select a role"  onLoad={() => onChange("participant_role_id", 0)} /> 
            <SubmitButton />
        </form>
    );
}

export default CreateEventParticipantForm;