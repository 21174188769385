import React, {useState} from 'react';

// import DropdownSelector from "../fragments/form/DropdownSelector"
import TextField from "../fragments/form/TextField"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import SubmitButton from "../fragments/form/SubmitButton"
import LocationSelect from "../components/SearchSelector/LocationSelect"


const CreateLocationForm = (props) => {
    const [formData, setFormData] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const updateLocationFields = (id, name, value) => {
        let newLocation = {...formData}
        if (id === 'country') {
          newLocation.country_id = value;
          newLocation.country = name;
          props.handleSetCountryId(value)
        }
        if (id === 'state') {
          newLocation.state_id = value;
          newLocation.state = name;
          props.handleSetStateId(value)
        }
        if (id === 'city') {
          newLocation.city_id = value;
          newLocation.city = name;
        }
        setFormData(newLocation);
      }
    
    const resetLocationFields =(id)=>{
        if(id === 'country'){
            formData.country = '';
            formData.country_id =null;
            formData.state = '';
            formData.state_id = null;
            formData.city = '';
            formData.city_id = null
        }
        if(id === 'state'){
            formData.state = '';
            formData.state_id = null;
            formData.city = '';
            formData.city_id = null
        }
        if(id === 'city'){
            formData.city = '';
            formData.city_id = null
        }
        setFormData({...formData});
    }

    const onChange = (id, value) => {
        formData[id] = value;
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        props.onSubmit(formData);
    }

    return(
        <div>
            <form onSubmit={onSubmit}>
                <TextField
                    id="name"
                    onChange={onChange}
                    label="Name"
                    required={true} 
                    onLoad={() => onChange("name", "")} 
                />
                <TextField
                    id="address_1"
                    onChange={onChange}
                    label="Address1"
                    required={true} 
                    onLoad={() => onChange("address_1", "")} 
                />
                <TextField
                    id="address_2"
                    onChange={onChange}
                    label="Address2"
                    required={false}
                    onLoad={() => onChange("address_2", "")} 
                />
                <LocationSelect
                    id="country"
                    required={true}
                    isSubmitted={isSubmitted}
                    formData={formData}
                    collection={props.countries}
                    countryId={props.countryId}
                    stateId={props.stateId}
                    handleSearchLocation={props.handleSearchLocation}
                    updateLocationFields={updateLocationFields}
                    resetLocationFields={resetLocationFields}
                    label="Country"
                    prompt="Please search and select a country"
                />
                <LocationSelect
                    id="state"
                    required={true}
                    isSubmitted={isSubmitted}
                    formData={formData}
                    collection={props.states}
                    countryId={props.countryId}
                    stateId={props.stateId}
                    handleSearchLocation={props.handleSearchLocation}
                    updateLocationFields={updateLocationFields}
                    resetLocationFields={resetLocationFields}
                    label="State"
                    prompt="Please select a country before select a state"
                />
                <LocationSelect
                    id="city"
                    required={true}
                    isSubmitted={isSubmitted}
                    formData={formData}
                    collection={props.cities}
                    countryId={props.countryId}
                    stateId={props.stateId}
                    handleSearchLocation={props.handleSearchLocation}
                    updateLocationFields={updateLocationFields}
                    resetLocationFields={resetLocationFields}
                    label="City"
                    prompt="Please select a state before select a city"
                />
                <TextField
                    id="zip"
                    onChange={onChange}
                    label="Zip"
                    required={true} 
                    onLoad={() => onChange("zip", "")} 
                />
                <TextField
                    id="longitude"
                    onChange={onChange}
                    label="Longitude"
                    required={true} 
                    onLoad={() => onChange("longitude", "")} 
                />
                <TextField
                    id="latitude"
                    onChange={onChange}
                    label="Latitude"
                    required={true} 
                    onLoad={() => onChange("latitude", "")} 
                />
                <CheckboxSelector
                    id="is_published"
                    onChange={onChange}
                    label="Publish"
                    onLoad={() => onChange("is_published", "0")}
                />
                <SubmitButton />
            </form>
        </div>
    )
}

export default CreateLocationForm;