import { GoogleOAuthProvider } from '@react-oauth/google';
import './App.css';

import Routing from './routes'

function App() {

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <div className="App">
        <Routing/>
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
