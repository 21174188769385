import {PopupboxManager} from 'react-popupbox';



const ConfirmDeletePopup = (props) => {
    const confirmLabel = props.confirmLabel || "Confirm";
    const cancelLabel = props.cancelLabel || "Cancel";

    const onConfirm = () => {
        if(props.onConfirm) {
            props.onConfirm();
        }
        PopupboxManager.close();
    }

    const onCancel = () => {
        if(props.onCancel) {
            props.onCancel();
        }
        PopupboxManager.close();
    }

    const popupboxConfig = {
        titleBar: {
            enable: true,
            text: props.header || 'Please confirm DELETE'
        },
        overlayClose:true,
        fadeIn: true,
        fadeInSpeed: 500
    }

    const content = (
        <div>
            <h4>{props.prompt}</h4>
            <row>
                <button onClick={onConfirm} className="btn btn-danger warning-btn">{confirmLabel}</button>
                <button onClick={onCancel}  className="btn btn-warning warning-btn">{cancelLabel}</button>
            </row>
        </div>
    )

    const open = () => {
        PopupboxManager.open({ content, config: popupboxConfig})
    }

    return (
        <i onClick={open} className="bi bi-trash">Delete</i>
    )
}

export default ConfirmDeletePopup;