
import {useParams, useNavigate} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import Page from "../Page";
import CreateSponsorForm from "../../forms/CreateSponsorForm"
import OrganizationApi from "../../services/organizations";
import SponsorApi from "../../services/sponsorships";

import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

function CreateSponsorPage(props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const organizationApi = new OrganizationApi(props.token);
    const sponsorApi = new SponsorApi(props.token);
    const routeParams = useParams();
    const { organizationId, eventId } = routeParams;
    const navigate = useNavigate();
    
    const onSubmit = (formData) => {
        let formatValue = parseInt(eventId)
        formData.set("event_id", formatValue);
        sponsorApi.create(formData).then((response) => {
            if (routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId) {
                navigate(`/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${routeParams.organizationId}/event/${routeParams.eventId}/sponsors`)
            } else {
                navigate(`/organization/${organizationId}/event/${eventId}/sponsors`);
            }
        })
        .catch((err) => {
            console.error(err.message);
            setError(err.message)
            setMessage("");
        });
    }

    const handleSearchSponsor= async(value)=>{
        try {
            let response = await organizationApi.getListByLetter('All',value,0) 
            let arrOrganization = response.organizations.map((e)=>({label: e.name, value: e.id,...e}))
            setOrganizations([...arrOrganization])
        } catch (err) {
            console.error(err.message);
            setError(err.message)
            setMessage("");
        }
      
    }

    const handleBack =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${routeParams.organizationId}/event/${routeParams.eventId}/sponsors`
        }else{
            return `/organization/${organizationId}/event/${eventId}/sponsors`
        }
    }

    return (
        <Page
            buttonText="Back"
            buttonUrl={handleBack()}
            header={`Create Sponsor`}
        >
            <Error error={error} />
            <Message message={message} />
            <CreateSponsorForm
                {...props}
                onSubmit={onSubmit}
                organizations={organizations}
                handleSearchSponsor={handleSearchSponsor}
                />
        </Page>
    );
}

export default CreateSponsorPage;