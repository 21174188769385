import React, {useEffect, useState} from "react"
import OrganizationApi from "../services/organizations";
import LocationApi from "../services/locations";
import {useParams, useNavigate} from "react-router-dom";

import Error from "../fragments/form/Error"
import Message from "../fragments/form/Message"
import TextField from "../fragments/form/TextField"
import TextAreaField from "../fragments/form/TextAreaField"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import ImageManager from "../fragments/form/ImageManager"
import SubmitButton from "../fragments/form/SubmitButton"
import DropdownSelector from "../fragments/form/DropdownSelector";
import SearchSelector from "../components/SearchSelector/RegionSelect";

function EditOrganizationForm(props) {
    const [formData, setFormData] = useState(new FormData());
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);


    const organizationApi = new OrganizationApi(props.token);
    const locationApi = new LocationApi(props.token);

    const navigate = useNavigate();
    const routeParams = useParams();
    const { organizationId } = routeParams

    const onChange = (id, value) => {
        formData.set(id, value);
        setFormData(formData);
    }

    const updateLocationFields = (id, name, value) => {
        let newLocation = {...props.location}
        if (id === 'country') {
            newLocation.country_id = value;
            newLocation.country = name;
          }
          if (id === 'state') {
            newLocation.state_id = value;
            newLocation.state = name;
          }
          if (id === 'city') {
            newLocation.city_id = value;
            newLocation.city = name;
          }
          props.handleSetLocation(newLocation);
      }

      const resetLocationFields = (id) => {
        if (!props.location) {
          return;
        }
    
        let newLocation = { ...props.location };
        if (id === 'country') {
          newLocation.country = '';
          newLocation.country_id = null;
          newLocation.state = '';
          newLocation.state_id = null;
          newLocation.city = '';
          newLocation.city_id = null;
        } else if (id === 'state') {
          newLocation.state = '';
          newLocation.state_id = null;
          newLocation.city = '';
          newLocation.city_id = null;
        } else if (id === 'city') {
          newLocation.city = '';
          newLocation.city_id = null;
        }
        props.handleSetLocation(newLocation);
      };

    const onChangeLocation = (id, value) => {
        let newLocation = {...props.location, [id]: value}
        props.handleSetLocation(newLocation);
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        try {
            if (props.organization) {
                props.location.name = props.organization.name;
                props.location.is_published = props.organization.is_published;
                if (!props.organization.location_id && props.location) {
                    props.location.organization_id = props.organization.id;
                    const newLocation = await locationApi.create(props.location);
                    formData.set("location_id", newLocation.id);
                } else if (props.organization.location_id && props.location) {
                    await locationApi.update(props.organization.location_id, props.location);
                }

                const organizationResponse = await organizationApi.update(organizationId, formData);
                if (routeParams.countryId && routeParams.stateId && routeParams.municipalityId) {
                    navigate(`/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}`);
                } else {
                    navigate(`/organization/${organizationId}`);
                }
            }
        } catch (err) {
            console.error("Error during update:", err.message);
            setError(err.message);
            setMessage("");
        }
    };
    
    if(!props.organization) {
        return (<div>Loading...</div>)
    }
    return(
        <div>
            <Error message={error} />
            <Message message={message} />

            {
                props.organization.is_corkboard ?   <form onSubmit={handleOnSubmit}>
                <TextField id="name" required={true} value={props.organization.name} onChange={onChange} label="Title" />
                <CheckboxSelector id="is_published" value={props.organization.is_published} onChange={onChange} label="Publish" />
                <SubmitButton />
            </form>
            :
            <form onSubmit={handleOnSubmit}>
                <DropdownSelector id="category_id" required={true} collection={props.categories} onChange={onChange} label="Category" prompt="Please select a category"  onLoad={() => onChange("category_id", 0)} selectedId={props.organization.category_id} />
                <TextField id="name" required={true} value={props.organization.name} onChange={onChange} label="Title" />
                <TextAreaField id="description" required={true} value={props.organization.description} onChange={onChange} label="Description" rows="10" cols="20"/>
                <TextField id="website_url" required={false} value={props.organization.website_url} onChange={onChange} label="Website" />
                <TextField id="twitter_name" required={false} value={props.organization.twitter_name}  onChange={onChange} label="Twitter Name" />
                <TextField id="facebook_name" required={false} value={props.organization.facebook_name}  onChange={onChange} label="Facebook Name" />
                <TextField id="instagram_name" required={false} value={props.organization.instagram_name}  onChange={onChange} label="Instagram Name" />
                <TextField id="linkedin_name" required={false} value={props.organization.linkedin_name}  onChange={onChange} label="LinkedIn Name" />
                <TextField id="email_address" required={false} value={props.organization.email_address}  onChange={onChange} label="Email Address" />
                <TextField id="phone_number" required={false} value={props.organization.phone_number}  onChange={onChange} label="Phone Number" />
                <TextField id="address_1" required={true} value={props.location ? props.location.address_1 : ''} onChange={onChangeLocation} label="Address 1" />
                <TextField id="address_2" required={true}  value={props.location ? props.location.address_2 : ''} onChange={onChangeLocation} label="Address 2" /> 
                <SearchSelector 
                id="country"
                required={true} 
                isSubmitted={isSubmitted} 
                location={props.location} 
                collection={props.countries} 
                updateLocationFields={updateLocationFields} 
                handleSearchLocation={props.handleSearchLocation} 
                resetLocationFields={resetLocationFields} 
                label="Country" 
                prompt="Please search and select a country"/>
                <SearchSelector 
                id="state" 
                required={true}  
                isSubmitted={isSubmitted} 
                location={props.location} 
                collection={props.states} 
                updateLocationFields={updateLocationFields} 
                handleSearchLocation={props.handleSearchLocation} 
                resetLocationFields={resetLocationFields} 
                label="State" 
                prompt="Please select a country before select a state"/>
                <SearchSelector 
                id="city" 
                required={true}  
                isSubmitted={isSubmitted} 
                location={props.location} 
                collection={props.cities} 
                updateLocationFields={updateLocationFields} 
                handleSearchLocation={props.handleSearchLocation} 
                resetLocationFields={resetLocationFields}  
                label="City" 
                prompt="Please select a state before select a city"/>            
                <TextField id="zip" required={true} value={props.location ? props.location.zip : ''} onChange={onChangeLocation} label="Zip" />
                <TextField id="longitude" type="number"  required={true} value={props.location ? props.location.longitude : null} onChange={onChangeLocation} label="Longtitude"/>
                <TextField id="latitude" type="number" required={true} value={props.location ? props.location.latitude : null} onChange={onChangeLocation} label="Latitude" />
                <ImageManager id="card_image" required={false} url={props.organization.card_image.url} label="Card Image" onChange={onChange} />
                <ImageManager id="profile_image" required={false} url={props.organization.profile_image.url} label="Profile Image" onChange={onChange} />
                <CheckboxSelector id="is_published" value={props.organization.is_published} onChange={onChange} label="Publish" />
                <SubmitButton />
            </form>
            }
        </div>
    )
}

export default EditOrganizationForm;