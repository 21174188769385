import React, { useState, useEffect } from "react"
import SponsorApi from "../../services/sponsorships";
// import Page from "../Page"
import SponsorsTable from "../../tables/SponsorsTable"
import DashboardHeader from '../../components/DashboardHeader';
import { PopupboxContainer } from 'react-popupbox';
import {useParams} from "react-router-dom";

function SponsorshipsPage(props) {
    const [sponsors, setSponsors] = useState([]);
    const sponsorsApi = new SponsorApi(props.token);
    const [search, setSearch] = useState('');
    const routeParams = useParams();
    const { organizationId, eventId } = routeParams;

    const fetch = (eventId) => {
        sponsorsApi.getSponsors(eventId).then((sponsors) => {
            setSponsors([...sponsors])
        })
    }

    useEffect(() => {
        fetch(eventId)
    }, []);

    // Search
    const __handleSearch = (event) => {
        setSearch(event.target.value);
        if (event.target.value !== '') {
            let search_results = sponsors.filter((item) => item.name.toLowerCase().includes(event.target.value.toLowerCase())
            );
            setSponsors([...search_results]);
        }
        else {
            fetch(eventId)
        }
    };

    const handleOpenCreateEventPage =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${routeParams.organizationId}/event/${routeParams.eventId}/sponsor/new`
        }else{
            return `/organization/${organizationId}/event/${eventId}/sponsor/new`
        }
    }

    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="New Sponsor" link={handleOpenCreateEventPage()} />
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Sponsors</h2>
                    <div className='dashboard-content-search'>
                        <input
                            type='text'
                            value={search}
                            placeholder='Search..'
                            className='dashboard-content-input'
                            onChange={e => __handleSearch(e)} />
                    </div>
                </div>
                <div className="organizations-custom-popup">
                    <PopupboxContainer />
                </div>
                    {
                        sponsors.length> 0 ?
                            <SponsorsTable sponsors={sponsors} {...props} refresh={(eventId)=>fetch(eventId)} eventId={eventId} organizationId={organizationId}/>
                            :
                            <div className='dashboard-content-footer'>
                                <span className='empty-table'>No data</span>
                            </div> 
                    }
               
            </div>
        </div>
    )
}

export default SponsorshipsPage