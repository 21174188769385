import API from './api'

const {REACT_APP_API_URI} = process.env;


const updateProperty = (subject, keyProperty, valueProperty, collection, defaultValue) => {
    if(typeof defaultValue == "undefined") {
        defaultValue = null;
    }
    const key = subject[keyProperty];
    if(key && collection[key]) {
        subject[valueProperty] = collection[key]
    } else {
        subject[valueProperty] = defaultValue;
    }
    return subject
}

const formatOrganizations = (response) => {
    let organizations = Object.values(response.data.data.organizations);
    const users = response.data.data.users;
    const images = response.data.data.images;
    const categories = response.data.data.categories;
    const locations = Object.values(response.data.data.locations)
  
    return organizations.map((organization) => {
        organization = updateProperty(organization, "created_by", "created_by", users);
        organization = updateProperty(organization, "updated_by", "updated_by", users);
        organization = updateProperty(organization, "deleted_by", "deleted_by", users);
        
        organization = updateProperty(organization, "profile_image_id", "profile_image", images);
        organization = updateProperty(organization, "card_image_id", "card_image", images);
        let location = locations.find((item) => item.id === organization.location_id)
        organization.city = location && location.city ? location.city : '';
        organization.state = location && location.state ? location.state : '';
        organization.country = location &&  location.country ? location.country : '';
        return organization;
    });
}

class MunicipalityApi {
    constructor(token) {
        this.api = new API(token);
        this.baseUrl = `${REACT_APP_API_URI}/admin/state`
    }
 
    all(stateId, pageNumber) {
        try {
            return this.api.get(`${this.baseUrl}/${stateId}/cities?page_num=${pageNumber}`)
            .then((response) => {
                return {
                    cities: Object.values(response.data.data.cities),
                    states: Object.values(response.data.data.states),
                    countries: Object.values(response.data.data.countries),
                    total_pages: response.data.data.total_pages
                }
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    search(stateId, city, pageNumber) {
        try {
            return this.api.get(`${this.baseUrl}/${stateId}/cities?page_num=${pageNumber}&city=${city}`)
            .then((response) => {
                return {
                    cities: Object.values(response.data.data.cities),
                    total_pages: response.data.data.total_pages
                }
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    getById(id) {
        try {
            return this.api.get(`${REACT_APP_API_URI}/admin/city/${id}`)
            .then((response) => {
                return response.data.data.city
            });
        } catch(error) {
            console.error(`Error fetching municipality. id=${id}, error=${error}`);
            return {}
        }
    }

    update(id, body) {
        try{
            return this.api.patch(`${REACT_APP_API_URI}/admin/city/${id}`, body, "multipart/form-data")
            .then((response) => {
                return response;
            });
        }catch(error) {
            console.error(`Error updating municipality. id=${id}, error=${error}`);
            return {}
        }
    }

    create(body) {
        try{
            return this.api.post(`${REACT_APP_API_URI}/admin/city`, body, "multipart/form-data")
            .then((response) => {
                return response;
            });
        }catch(error) {
            console.error(`Error creating municipality. error=${error}`);
            return {}
        }
    }

    getOrganizationsByCity(city, pageNumber){
        try{
            return this.api.get(`${REACT_APP_API_URI}/admin/organization?city=${city}&corkboard=${'All'}&page_num=${pageNumber}&status=${'Published'}`)
            .then((response) => {
                return {
                    organizations: formatOrganizations(response),
                    total_pages: response.data.data.total_pages
                };
            });
        }catch(error) {
            console.error(`Error updating municipality. city=${city}, error=${error}`);
            return {}
        }
    }

    getlist(stateId,maxCities){
        try {
            return this.api.get(`${REACT_APP_API_URI}/admin/state/${stateId}/cities?max_cities=${maxCities}`)
            .then((response) => {
                return Object.values(response.data.data.cities)
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    getCitiesByName(stateId, city) {
        try {
            return this.api.get(`${REACT_APP_API_URI}/admin/state/${stateId}/cities?city=${city}`)
                .then((response) => {
                    return Object.values(response.data.data.cities)
                });
        } catch (error) {
            console.error(error);
            return []
        }
    }

    sortOrganizationsByName(city,pageNumber, value){
        try {
            return this.api.get(`${REACT_APP_API_URI}/admin/organization-v2?city=${city}&corkboard=${'All'}&page_num=${pageNumber}&status=${'Published'}&sort_by_name=${value}`)
                .then((response) => {
                    return {
                        organizations: formatOrganizations(response),
                        total_pages: response.data.data.total_pages
                    };
                });
        } catch (error) {
            console.error(error);
            return []
        }
    }

    sortOrganizationsById(city,pageNumber, value){
        try {
            return this.api.get(`${REACT_APP_API_URI}/admin/organization-v2?city=${city}&corkboard=${'All'}&page_num=${pageNumber}&status=${'Published'}&sort_by_id=${value}`)
                .then((response) => {
                    return {
                        organizations: formatOrganizations(response),
                        total_pages: response.data.data.total_pages
                    };
                });
        } catch (error) {
            console.error(error);
            return []
        }
    }
    searchOrganizationsByName(city,pageNumber,organizationName){
        try{
            return this.api.get(`${REACT_APP_API_URI}/admin/organization?city=${city}&corkboard=${'All'}&name=${organizationName}&page_num=${pageNumber}&status=${'Published'}`)
            .then((response) => {
                return {
                    organizations: formatOrganizations(response),
                    total_pages: response.data.data.total_pages
                };
            });
        }catch(error) {
            console.error(`Error updating municipality. city=${city}, error=${error}`);
            return {}
        }
    }

    searchCityById(id) {
        try {
            return this.api.get(`${REACT_APP_API_URI}/admin/city/${id}`)
            .then((response) => {
                return {
                    cities: Object.values(response.data.data),
                    total_pages: 1
                }
            });
        } catch(error) {
            console.error(`Error fetching municipality. id=${id}, error=${error}`);
            return {}
        }
    }

    filterByLetter(stateId,value,pageNumber){
        try {
            let letter = value.toLowerCase()
            return this.api.get(`${this.baseUrl}/${stateId}/cities?city_start=${letter}&page_num=${pageNumber}`)
                .then((response) => {
                    return {
                        cities: Object.values(response.data.data.cities),
                        total_pages: response.data.data.total_pages
                    }
                });
        } catch (error) {
            console.error(error);
            return []
        }
    }

    filterOrganizationsByLetter(cityName,value, pageNumber){
        try {
            let letter = value.toLowerCase()
            return this.api.get(`${REACT_APP_API_URI}/admin/organization?city=${cityName}&corkboard=${'All'}&status=${'Published'}&name_start=${letter}&page_num=${pageNumber}`)
                .then((response) => {
                    return {
                        organizations: formatOrganizations(response),
                        total_pages: response.data.data.total_pages
                    }
                });
        } catch (error) {
            console.error(error);
            return []
        }
    }

    getCityListByLetter(stateId, value){
        try {
            let letter = value.toLowerCase()
            return this.api.get(`${this.baseUrl}/${stateId}/cities?city_start=${letter}`)
                .then((response) => {
                    return {
                        cities: Object.values(response.data.data.cities),
                        total_pages: response.data.data.total_pages
                    }
                });
        } catch (error) {
            console.error(error);
            return []
        }
    }
}

export default MunicipalityApi;