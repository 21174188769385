import API from './api'

const {REACT_APP_API_URI} = process.env;

const formatSponsors = (response) => {
    let sponsors = Object.values(response.data.data.sponsorships).map((sponsorship) => {
        let sponsorData = Object.values(response.data.data.sponsors).find((e)=> e.id === sponsorship.sponsor_id);
        if(sponsorData){
            return  {...sponsorData,...sponsorship}
        }else{
            sponsorship.name = ""
            return sponsorship
        }
    });
    return sponsors
}

class SponsorApi {
    constructor(token) {
        this.api = new API(token);
        this.baseUrl = `${REACT_APP_API_URI}`
    }

    getSponsors(eventId) {
        try {
            return this.api.get(`${this.baseUrl}/admin/event/${eventId}/sponsorships`)
            .then((response) => {
                return formatSponsors(response);
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    create(body) {
        try{
            return this.api.post(`${this.baseUrl}/admin/sponsorships`, body, "multipart/form-data")
            .then((response) => {
                return response.data.data.sponsorships
            });
        }catch(error) {
            console.error(`Error creating sponsorsor. error=${error}`);
            return {}
        }
    }

    delete(id) {
        try{
            return this.api.delete(`${this.baseUrl}/admin/sponsorships/${id}`)
            .then((response) => {
                return response.data.data;
            });
        }catch(error) {
            console.error(`Error deleting sponsorsor. error=${error}`);
            return {}
        }
    }
}

export default SponsorApi;