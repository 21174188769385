import React, { useState, useEffect } from "react"
import OrganizationsApi from "../../services/organizations";
import OrganizationsTable from "../../tables/OrganizationsTable"
import DashboardHeader from '../../components/DashboardHeader';
import { PopupboxContainer } from 'react-popupbox';
import LetterFilter from "../../components/LetterFilter";
import Pagination from "../../components/Pagination"

function OrganizationsPage(props) {
    const [organizations, setOrganizations] = useState([]);
    const organizationsApi = new OrganizationsApi(props.token);
    const [search, setSearch] = useState('');
    const [searchCity, setSearchCity] = useState('');
    const [searchStatus, setSearchStatus] = useState('All');
    const [totalPages, setTotalPages] = useState(0);
    const [pageItemActive, setPageItemActive] = useState(1);
    const [activeLetter, setActiveLetter] = useState('all'); 

    const fetch = async() => {
        try {
            if(search && searchCity ){
                let response = await organizationsApi.searchByCity(searchCity, searchStatus,'0', pageItemActive)
                    let filteredData = response.organizations.filter((org) => org.name.toLowerCase().includes(search.toLowerCase()))
                    setOrganizations([...filteredData]);
                    setTotalPages(response.total_pages);
            }
            else if (searchCity ) {
                let response = await  organizationsApi.searchByCity(searchCity, searchStatus, '0', pageItemActive)
                setOrganizations(response.organizations);
                setTotalPages(response.total_pages);
            }
            else if (activeLetter && activeLetter !== 'all') {
                let response = await organizationsApi.filterByLetter(searchStatus, activeLetter,'0',  pageItemActive);
                setOrganizations(response.organizations);
                setTotalPages(response.total_pages);
            }   
            else if (search) {
                let response = await organizationsApi.search(search, searchStatus,'0', pageItemActive)
                setOrganizations(response.organizations);
                setTotalPages(response.total_pages);
            }
            else{
                let response = await organizationsApi.all(searchStatus,'0', pageItemActive)
                setOrganizations(response.organizations);
                setTotalPages(response.total_pages);
                if(!search && !searchCity){
                    setActiveLetter('all');
                }
            }
        } catch (error) {
            console.error(error);
            setOrganizations([]);
        }
    }

    useEffect(()=>{
        fetch()
    },[search, searchCity, searchStatus, activeLetter, pageItemActive])


    const __handleSearch = (event) => {
        setSearch(event.target.value);
        setActiveLetter(''); 
    };

    const handleSearchByCity =(event)=>{
        let value = event.target.value;
        setSearchCity(value);
        setActiveLetter(''); 
    }

    const handleSearchByStatus =(event)=>{
        let value = event.target.value;
        setSearchStatus(value);
    }
  
    const handleLetterClick = (letter) => {
        setSearch('')
        setSearchCity('')
        setPageItemActive(1); 
        if(letter === 'all'){
            setActiveLetter('all'); 
        }else{
            setActiveLetter(letter); 
        }
      };

    const handleSelectPage=(pageNumber)=>{
        setPageItemActive(pageNumber);
    }
    
    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="New Organization" link='/organization/new' />
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Organizations</h2>
                    <div>
                        <div className='dashboard-content-search mx-2'>
                            <select                                
                                value={searchStatus}                                
                                className='dashboard-content-input search-status-input'
                                onChange={e => handleSearchByStatus(e)} >                                
                                <option value="All" selected="selected">All</option>    
                                <option value="Published">Published</option>    
                                <option value="Unpublished">Unpublished</option>
                            </select>
                        </div>
                        <div className='dashboard-content-search'>
                            <input
                                type='text'
                                value={search}
                                placeholder='Search by name..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearch(e)} />
                        </div>
                        <div className='dashboard-content-search search-city-input'>
                            <input
                                type='text'
                                value={searchCity}
                                placeholder='Search by city..'
                                className='dashboard-content-input'
                                onChange={e => handleSearchByCity(e)} />
                        </div>
                    </div>
                </div>
                <LetterFilter activeLetter={activeLetter} onLetterClick={handleLetterClick}/>
                <div className="organizations-custom-popup">
                    <PopupboxContainer />
                </div>
                    {
                        organizations.length > 0 ?
                            <OrganizationsTable organizations={organizations} {...props} refresh={fetch} />
                            :
                            <div className='dashboard-content-footer'>
                                <span className='empty-table'>No data</span>
                            </div>
                    }
                <Pagination totalPages={totalPages} pageItemActive={pageItemActive} handleSelectItem={handleSelectPage}/>
            </div>
        </div>
    )
}

export default OrganizationsPage