const PanelItem = (props) => (
    <div className="panel-item">
        <div className="panel-label">
            {props.label}
        </div>
        <div className="panel-value">
            {props.children}
        </div>
        <div className="panel-clear"></div>
    </div>
)

export default PanelItem;
