import YesIcon from '../../assets/icons/done.svg';
import NoIcon from '../../assets/icons/cancel.svg';

const BoolValue = (props) => (
    <div>
        {props.value ? "Yes" : "No"}
        {props.value ?
            <img
                src={YesIcon}
                className='dashboard-content-icon'/>
            : <img
                src={NoIcon}
                className='dashboard-content-icon'/>
        }
    </div>
)

export default BoolValue;
