import API from './api'

const {REACT_APP_API_URI} = process.env;

const formatParticipants = (response) => {
    let participants = Object.values(response.data.data.event_participants).map((participant) => {
        let participantData = Object.values(response.data.data.participants).find((e)=> e.id === participant.participant_id);
        let participantRoleData = Object.values(response.data.data.participantion_roles).find((e)=> e.id === participant.participant_role_id);
            if(participantData){
                participant = {...participantData,...participant}
                participant.participant_name =  participantData.name
            }else{
                participant.participant_name = ""
            }

            if(participantRoleData){
                participant = {...participantRoleData,...participant}
                participant.participant_role_name =  participantRoleData.name
            }else{
                //nothing
            }
        return participant
    });
    return participants
}

class ParticipantApi {
    constructor(token) {
        this.api = new API(token);
        this.baseUrl = `${REACT_APP_API_URI}`
    }
   
    getParticipants(eventId) {
        try {
            return this.api.get(`${this.baseUrl}/admin/event/${eventId}/event-participants`)
            .then((response) => {
                return formatParticipants(response);
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    getRoleById(id){
        try {
            return this.api.get(`${this.baseUrl}/admin/event-participant/${id}/participation-role`)
            .then((response) => {
                return response.data.data.participation_role
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    create(body) {
        try{
            return this.api.post(`${this.baseUrl}/admin/event-participants`, body, "multipart/form-data")
            .then((response) => {
                return response.data.data
            });
        }catch(error) {
            console.error(`Error creating event participant. error=${error}`);
            return {}
        }
    }

    delete(id) {
        try{
            return this.api.delete(`${this.baseUrl}/admin/event-participants/${id}`)
            .then((response) => {
                return response.data.data;
            });
        }catch(error) {
            console.error(`Error deleting event participant. error=${error}`);
            return {}
        }
    }
}

export default ParticipantApi;