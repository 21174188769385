import React, {useState, useEffect} from "react"
import {useParams, useNavigate} from "react-router-dom";

import OrganizationApi from "../../services/organizations";
import LocationApi from "../../services/locations";
import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav";
import OrganizationLocationHeaderNav from "../../fragments/nav/OrganizationLocationHeaderNav"
import EditLocationForm from "../../forms/EditLocationForm"
import CountryApi from "../../services/countries";
import StateApi from "../../services/states";
import MunicipalityApi from "../../services/municipalities";

import Page from "../Page"
import Loading from "../../fragments/Loading"
import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"


function EditOrganizationLocationPage(props) {
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [location, setLocation] = useState();
    const [organization, setOrganization] = useState();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([])
    const [countryId, setCountryId] = useState(0);
    const [stateId, setStateId] = useState(0);
    const [cityId, setCityId] = useState(0);

    const locationApi = new LocationApi(props.token);
    const organizationApi = new OrganizationApi(props.token);
    const countryApi = new CountryApi(props.token);
    const stateApi = new StateApi(props.token);
    const municipalityApi = new MunicipalityApi(props.token);

    const routeParams = useParams();
    const { organizationId, locationId } = routeParams
    const navigate = useNavigate();

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
        locationApi.getById(locationId).then((response) => {
            setLocation(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    },[]);

    const handleSetCountryId=(id)=>{
        setCountryId(id)
    }

    const handleSetStateId=(id)=>{
        setStateId(id)
    }

    const handleSetCityId=(id)=>{
        setCityId(id)
    }
       
    const handleSearchCountry =async(value)=>{
        try {
            if(value){
                let response = await countryApi.getCountryListByLetter(value)
                let arrCountry =  response.countries.map((e)=>({label: e.country , value: e.id,...e}))
                setCountries([...arrCountry])
            }else{
                setCountries([])
            }
        } catch (error) {
            console.error("Failed to fetch countries:", error);
        }
      
    }

    const handleSearchState =async(countryId, value)=>{
        try {
            if(value){
                let response = await stateApi.getStateListByLetter(countryId,value)
                let arrState=  response.states.map((e)=>({label: e.state , value: e.id,...e}))
                setStates([...arrState])
            }else{
                setStates([])
            }
        } catch (error) {
            console.error("Failed to fetch states:", error);
        }
    }

    const handleSearchCity = async (stateId, value)=>{
        try {
            if(value){
                let response = await municipalityApi.getCityListByLetter(stateId,value)
                let arrCity=  response.cities.map((e)=>({label: e.city , value: e.id,...e}))
                setCities([...arrCity])
            }else{
                setCities([])
            }
        } catch (error) {
            console.error("Failed to fetch cities:", error);
        }
    }

    const handleSearchLocation = (id, countryId, stateId, value)=>{
        if(id === 'country'){
            handleSearchCountry(value)
        }
        else if(id === 'state'){
            handleSearchState(countryId, value)
        }
        else if(id === 'city'){
            handleSearchCity(stateId, value)
        }else{
            //
        }
    }

    const onSubmit = (formData) => {
        locationApi.update(locationId, formData).then((response) => {
            if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
                navigate(`/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${routeParams.organizationId}/locations`);
            }else{
                navigate(`/organization/${organizationId}/locations`);
            }
        })
        .catch((err) => {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        });
    }

    const handleBack =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${routeParams.organizationId}/locations`
        }else{
            return `/organization/${organizationId}/locations`
        }
    }

    if(!organization || !location) {
        return (<Loading />)
    }
    return(
        <Page 
            buttonText="Back"
            buttonUrl={handleBack()}
            header={`Viewing location "${location.name}" for organization: ${organization.name}`}
        >
            {routeParams.countryId && routeParams.stateId && routeParams.municipalityId && routeParams.organizationId ?
                <OrganizationLocationHeaderNav countryId={routeParams.countryId} stateId={routeParams.stateId} municipalityId={routeParams.municipalityId} organizationId={routeParams.organizationId} />
                :
                <OrganizationHeaderNav organizationId={organizationId} />
            }
            <Error message={error} />
            <Message message={message} />
            <EditLocationForm
                location={location}
                organization={organization}
                countries={countries}
                states={states}
                cities={cities}
                countryId={countryId}
                stateId={stateId}
                cityId={cityId}
                handleSetCountryId={handleSetCountryId}
                handleSetStateId={handleSetStateId}
                handleSetCityId={handleSetCityId}
                handleSearchLocation={handleSearchLocation}
                onSubmit={onSubmit}
            />
        </Page>
    )
}

export default EditOrganizationLocationPage;