
import {useParams, useNavigate} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import Page from "../Page";
import CreateCountryForm from "../../forms/MunicipleHierarchy/CreateCountryForm"
import CountryApi from "../../services/countries";
import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

function CreateCountryPage(props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const countryApi = new CountryApi(props.token);
    const navigate = useNavigate();

    const onSubmit = (formData) => {
        countryApi.create(formData).then((response) => {
            navigate(`/countries`)
        })
        .catch((err) => {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        });
    }


    return (
        <Page
            buttonText="Back"
            buttonUrl="/countries"
            header={`Create Country`}
        >
            <Error error={error} />
            <Message message={message} />
            <CreateCountryForm
                {...props}
                onSubmit={onSubmit}
                />
        </Page>
    );
}

export default CreateCountryPage;