import axios from "axios";

const {REACT_APP_API_URI} = process.env;

const BaseUrl = `${REACT_APP_API_URI}/sponsor`


export function getSponsors() {
    try{

        return axios.get(`${BaseUrl}`)
    }catch(error) {
        return[]
    }
}

export function getSponsor(id) {
    try{
        return axios.get(`${BaseUrl}/${id}`)
    }catch(error) {
        return[]
    }
}

export function updateSponsor(id,body,token) {
    try{
        return axios.patch(`${BaseUrl}/${id}`,body,{
            headers: { 'Authorization': 'Bearer ' + token }
        })
    }catch(error) {
        return[]
    }
}

export function createSponsor(body,token) {
    try{
        return axios.post(`${BaseUrl}`,body,{
            headers: { 'Authorization': 'Bearer ' + token }
        })
    }catch(error) {
        return[]
    }
}

export function deleteSponsor(id,token) {
    try{
        return axios.delete(`${BaseUrl}/${id}`,{
            headers: { 'Authorization': 'Bearer ' + token }
        })
    }catch(error) {
        return[]
    }
}