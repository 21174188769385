import {useEffect,useState} from 'react';
import {useLocation} from "react-router-dom";
import DashboardHeader from '../../components/DashboardHeader';
import '../styles.css';
import {getSlide} from "../../services/slides";
import EditSlideform from "./edit-form";

function SingleSlide(props) {
    const  location = useLocation();
    const { id } = location.state;

    const [data, setData] = useState('');

    useEffect(() => {
        getSlide(id).then((response) => {
            setData(response.data);
        })
            .catch((err) => {
                console.log(err.message);
            });
    },[]);
    if (!data) return null;
    console.log(data);

    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="Back" link="/slides" />

            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Edit Slide : {data.data.name}  </h2>



                </div>
                <EditSlideform token={props.token} slide={data.data}/>
            </div>
        </div>
    );
}

export default SingleSlide;