import {useEffect,useState} from 'react';
import DashboardHeader from "../components/DashboardHeader";
import {getDashboard} from "../services/dashboard";
import DashboardCards from "../components/DashboardCards";

function HomePage(props) {
    const [data, setData] = useState('');

    useEffect(() => {
        getDashboard(props.token).then((response) => {
            setData(response.data);
        })
            .catch((err) => {
                console.log(err.message);
        });
    },[]);
    if (!data) return null;


    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="User management" />

            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Dashboard </h2>

                </div>
                <DashboardCards data={data}/>
            </div>
        </div>
    );
  }
  
  export default HomePage;
  