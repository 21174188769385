import React, {useState} from "react"
import Error from "../fragments/form/Error"
import Message from "../fragments/form/Message"

import EventsApi from "../services/events";
import ItemActions from "../fragments/table/ItemActions"
import TextValue from "../fragments/table/TextValue"
import {useParams} from "react-router-dom";

const Location = (props) => {
    if(!props.location) {
        return "-";
    }
    return (<span>{props.location.name}</span>)
}

const Organization = (props) => {
    if(!props.organization) {
        return "-";
    }
    return (<span>{props.organization.name}</span>)
}

const Category = (props) => {
    if(!props.category) {
        return "-";
    }
    return (<span>{props.category.name}</span>)
}

function EventsTable(props){
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const eventsApi = new EventsApi(props.token);
    const routeParams = useParams();

    function deleteEvent(id){
        eventsApi.delete(id).then((response) => {
            setMessage(`Event with ID ${id} has been deleted`);
            setError("")
            props.refresh();
        })
        .catch((err) => {
            console.error(err.message);
            setError(err.message)
            setMessage("")
        });
    }

    function formatDate(currentDateTime) {
        const date = new Date(currentDateTime);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        let hours = date.getHours();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours %= 12;
        hours = String(hours).padStart(2, '0') || 12;
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
      }

    return(
        <div>
            <Error message={error} />
            <Message message={message} />
            <table>
                <tbody>
                <tr>
                    <th>ID</th>
                    <th>TITLE</th>
                    <th>DESCRIPTION</th>
                    <th>LOCATION</th>
                    <th>ORGANIZATION</th>
                    <th>CATEGORY</th>
                    <th>START TIME</th>
                    <th>END TIME</th>
                    <th>ACTIONS</th>
                </tr>
                    {props.events.map((event, index) => (
                        <tr key={index}>
                        <td><TextValue value={event.id} /></td>
                            <td><TextValue value={event.title} /></td>
                            <td><TextValue value={event.description} /></td>
                            <td><Location location={event.location} /></td>
                            <td><Organization organization={event.organization} /></td>
                            <td><Category category={event.category} /></td>
                            <td><TextValue value={formatDate(event.start_time)} /> </td>
                            <td><TextValue value={formatDate(event.end_time)} /></td>
                            <td>
                                {
                                    routeParams.countryId && routeParams.stateId && routeParams.municipalityId && props.organization.id ?
                                        <ItemActions
                                            onConfirm={() => deleteEvent(event.id, event.name)}
                                            editUrl={`/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${props.organization.id}/event/${event.id}/edit`}
                                            viewUrl={`/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${props.organization.id}/event/${event.id}`}
                                            itemName={`event "${event.title}"`}
                                        />
                                        :
                                        <ItemActions
                                            onConfirm={() => deleteEvent(event.id, event.name)}
                                            editUrl={`/organization/${props.organization.id}/event/${event.id}/edit`}
                                            viewUrl={`/organization/${props.organization.id}/event/${event.id}`}
                                            itemName={`event "${event.title}"`}
                                        />
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default EventsTable;