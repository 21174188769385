import { useState } from 'react';

import DropdownSelector from "../fragments/form/DropdownSelector"
import TextField from "../fragments/form/TextField"
import TextAreaField from "../fragments/form/TextAreaField"
import DateTimeSelector from "../fragments/form/DateTimeSelector"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import ImageManager from "../fragments/form/ImageManager"
import SubmitButton from "../fragments/form/SubmitButton"
import SearchSelector from '../components/SearchSelector/RegionSelect';


const EditEventForm = (props) => {
    const [formData, setFormData] = useState(new FormData());
    const [isSubmitted, setIsSubmitted] = useState(false);

    const onChange = (id, value) => {
        formData.set(id, value);
        setFormData(formData);
    }

    const updateLocationFields = (id, name, value) => {
        let newLocation = { ...props.location }
        if (id === 'country') {
            newLocation.country_id = value;
            newLocation.country = name;
        }
        if (id === 'state') {
            newLocation.state_id = value;
            newLocation.state = name;
        }
        if (id === 'city') {
            newLocation.city_id = value;
            newLocation.city = name;
        }
        props.handleSetLocation(newLocation);
    }

    const resetLocationFields = (id) => {
        if (!props.location) {
            return;
        }

        let newLocation = { ...props.location };
        if (id === 'country') {
            newLocation.country = '';
            newLocation.country_id = null;
            newLocation.state = '';
            newLocation.state_id = null;
            newLocation.city = '';
            newLocation.city_id = null;
        } else if (id === 'state') {
            newLocation.state = '';
            newLocation.state_id = null;
            newLocation.city = '';
            newLocation.city_id = null;
        } else if (id === 'city') {
            newLocation.city = '';
            newLocation.city_id = null;
        }
        props.handleSetLocation(newLocation);
    };

    const onChangeLocation = (id, value) => {
        let newLocation = { ...props.location, [id]: value }
        props.handleSetLocation(newLocation);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        props.onSubmit(formData,props.location);
    }

    return (
        <form onSubmit={onSubmit}>
            {/* <DropdownSelector id="location_id" required={true} collection={props.locations} onChange={onChange} value={props.event.location_id} label="Location" prompt="Please select a location" /> */}
            <DropdownSelector id="category_id" required={true} collection={props.categories} onChange={onChange} value={props.event.category_id} label="Category" prompt="Please select a category" />
            <TextField id="address_1" required={true} value={props.location ? props.location.address_1 : ''} onChange={onChangeLocation} label="Address 1" />
            <TextField id="address_2" required={true} value={props.location ? props.location.address_2 : ''} onChange={onChangeLocation} label="Address 2" />
            <SearchSelector
                id="country"
                required={true}
                isSubmitted={isSubmitted}
                location={props.location}
                collection={props.countries}
                updateLocationFields={updateLocationFields}
                handleSearchLocation={props.handleSearchLocation}
                resetLocationFields={resetLocationFields}
                label="Country"
                prompt="Please search and select a country" />
            <SearchSelector
                id="state"
                required={true}
                isSubmitted={isSubmitted}
                location={props.location}
                collection={props.states}
                updateLocationFields={updateLocationFields}
                handleSearchLocation={props.handleSearchLocation}
                resetLocationFields={resetLocationFields}
                label="State"
                prompt="Please select a country before select a state" />
            <SearchSelector
                id="city"
                required={true}
                isSubmitted={isSubmitted}
                location={props.location}
                collection={props.cities}
                updateLocationFields={updateLocationFields}
                handleSearchLocation={props.handleSearchLocation}
                resetLocationFields={resetLocationFields}
                label="City"
                prompt="Please select a state before select a city" />
            <TextField id="zip" required={true} value={props.location ? props.location.zip : ''} onChange={onChangeLocation} label="Zip" />
            <TextField id="longitude" type="number" required={true} value={props.location ? props.location.longitude : null} onChange={onChangeLocation} label="Longtitude" />
            <TextField id="latitude" type="number" required={true} value={props.location ? props.location.latitude : null} onChange={onChangeLocation} label="Latitude" />
            <ImageManager id="card_image" required={false} url={props.event.card_image.url} label="Card Image" onChange={onChange} />
            <ImageManager id="profile_image" required={false} url={props.event.profile_image.url} label="Profile Image" onChange={onChange} />
            <TextField id="title" required={true} value={props.event.title} onChange={onChange} label="Title" />
            <TextAreaField id="description" required={true} value={props.event.description} onChange={onChange} label="Description" rows="10" cols="20" />
            <TextField id="website" required={false} value={props.event.website} onChange={onChange} label="Website" />
            <TextField id="buy_ticket_url" required={false} value={props.event.buy_ticket_url} onChange={onChange} label="Buy Ticket Url" />
            <DateTimeSelector id="start_time" required={true} value={props.event.start_time} onChange={onChange} label="Start Time" />
            <DateTimeSelector id="end_time" required={true} value={props.event.end_time} onChange={onChange} label="End Time" />
            <CheckboxSelector id="is_published" value={props.event.is_published} onChange={onChange} label="Publish" />
            <SubmitButton />
        </form>
    );
}

export default EditEventForm;
