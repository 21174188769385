import HeaderNav from './HeaderNav'
import HeaderNavItem from './HeaderNavItem'

const EventLocationHeaderNav = (props) => (
    <HeaderNav>
        <HeaderNavItem location={`/country/${props.countryId}/state/${props.stateId}/municipality/${props.municipalityId}/organization/${props.organizationId}/event/${props.eventId}/participants`} label="Participants" />
        <HeaderNavItem location={`/country/${props.countryId}/state/${props.stateId}/municipality/${props.municipalityId}/organization/${props.organizationId}/event/${props.eventId}/participant/roles`} label="Participant Roles" />
        <HeaderNavItem location={`/country/${props.countryId}/state/${props.stateId}/municipality/${props.municipalityId}/organization/${props.organizationId}/event/${props.eventId}/sponsors`} label="Sponsors" />
    </HeaderNav>
)

export default EventLocationHeaderNav;