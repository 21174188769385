
const FormGroup = (props) => (
    <div className="col-md-10 mb-3">
        <label htmlFor={props.id}><b>{props.label}</b></label>
        <div className="form-group">
            {props.children}
        </div>
    </div>
)

export default FormGroup;