import API from './api'


const {REACT_APP_API_URI} = process.env;

class LocationApi {
    constructor(token) {
        this.api = new API(token);
        this.baseUrl = `${REACT_APP_API_URI}/admin/location`
    }

    search() {
        try {
            return this.api.get(this.baseUrl)
            .then((response) => {
                return Object.values(response.data.data.locations);
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    getById(id) {
        try {
            return this.api.get(`${this.baseUrl}/${id}`)
            .then((response) => {
                return response.data.data.location;
            });
        } catch(error) {
            console.error(`Error fetching location. id=${id}, error=${error}`);
            return {}
        }
    }

    getByOrganizationId(organization_id) {
        try {
            return this.api.get(`${REACT_APP_API_URI}/admin/organization/${organization_id}/locations`)
            .then((response) => {
                return Object.values(response.data.data.locations);
            });
        } catch(error) {
            console.error(`Error fetching location. organization_id=${organization_id}, error=${error}`);
            return {}
        }
    }

    update(id, body) {
        try{
            return this.api.patch(`${this.baseUrl}/${id}`, body)
            .then((response) => {
                return response.data.data.location;
            });
        }catch(error) {
            console.error(`Error updating location. id=${id}, error=${error}`);
            return {}
        }
    }

    create(body) {
        try{
            return this.api.post(this.baseUrl, body, "application/json")
            .then((response) => {
                return response.data.data.location;
            });
        }catch(error) {
            console.error(`Error creating location. error=${error}`);
            return {}
        }
    }

    delete(id) {
        try{
            return this.api.delete(`${this.baseUrl}/${id}`)
            .then((response) => {
                return response.data.data;
            });
        }catch(error) {
            console.error(`Error deleting location. error=${error}`);
            return {}
        }
    }
}

export default LocationApi;