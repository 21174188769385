import React, {useState} from 'react';
import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

import TextValue from "../../fragments/table/TextValue"
import {Link, useParams} from "react-router-dom";

function MunicipalityTable (props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const {countryId,stateId} = useParams();

    return(
        <div>
            <Error message={error} />
            <Message message={message} />
            <table>
                <tbody>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>STATUS</th>
                        <th>ACTIONS</th>
                    </tr>
                    {props.municipalities.map((municipality, index) => (
                        <tr key={index}>
                            <td><TextValue value={municipality.id} /></td>
                            <td><TextValue value={municipality.city} /></td>
                            <td><TextValue value={municipality.is_published ? 'Published': 'Pending'} /></td>
                            <td>
                                <span>
                                    <Link to={`/country/${countryId}/state/${stateId}/municipality/${municipality.id}`}>
                                        <i className="bi">View</i>
                                    </Link>
                                    <br />
                                    <Link to={`/country/${countryId}/state/${stateId}/municipality/${municipality.id}/edit`}>
                                        <i className="bi bi-pen">Edit</i>
                                    </Link>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default MunicipalityTable;