
import {Link} from "react-router-dom";
import ConfirmDeletePopup from "../../fragments/popup/ConfirmDeletePopup";

const ItemActions = (props) => (
    <span>
        <Link to={props.viewUrl}>
            <i className="bi">View</i>
        </Link>
        <br />
        <Link to={props.editUrl}>
            <i className="bi bi-pen">Edit</i>
        </Link>
        <br/>
        <ConfirmDeletePopup
            onConfirm={props.onConfirm}
            prompt={`Are you sure to delete ${props.itemName} ?`}
            acceptLabel="Delete"
        />
    </span>
)

export default ItemActions;