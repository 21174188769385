import ImagePreviewer from "./ImagePreviewer";
import ImageSelector from "./ImageSelector";

const ImageManager = (props) => (
    <div>
        <div style={{float:'left'}}>
            <ImagePreviewer url={props.url} />
        </div>
        <div style={{float:'left'}}>
            <ImageSelector {...props} />
        </div>
        <div style={{clear:'both'}}></div>
    </div>
)

export default ImageManager;