import React, {useState} from "react"
import CategoryApi from "../../services/categories";

function EditCategoryform(props){
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const single = props.category
    const icon = props.icon
    const categoryApi = new CategoryApi(props.token);

    const styles = {
        backgroundImage: `url(${icon.url})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '30px',
        height: '30px',
    };

    const [response, setResponse] = useState({
        "name": single.name,
        "is_event": single.is_event,
        "is_organization": single.is_organization,
        "icon_file": icon,
    })

    const handleOnChange = e => {
        setResponse({...response, [e.target.name]: e.target.value})
    }

    const onCheckChange = e => {
        setResponse({ ...response, [e.target.name]: !response[e.target.name] })
    }

    const onFileChange = e => {
        setResponse({ ...response, "icon_file": e.target.files[0]});
    }

    function updateSingleCategory(id,body){
        if(!response.is_event && !response.is_organization){
            setError("It should be event or organization category or both");
            return;
        }
        categoryApi.update(id,body).then((categoryResponse) => {
            console.log(categoryResponse);
            setData(categoryResponse);
        })
        .catch((err) => {
            let errorResponse = err.response.data;

            if(errorResponse?.data?.message){
                setError(errorResponse.data.message);
            }else{
                setError(err.message);
            }
        });
    }

    const handleOnSubmit = e => {
        e.preventDefault();
        updateSingleCategory(single.id, {
            "name": response.name,
            "is_event": response.is_event,
            "is_organization": response.is_organization,
            "icon_file": response.icon_file
        })
    }


    return(
        <div className="card-body">
            {error ? <div className="alert alert-danger" role="alert">
                {error}
            </div>: null }
            {data ? <div className="alert alert-success" role="alert">
              Category '{data.name}' has been updated successfully
            </div>: null }


        <form onSubmit={handleOnSubmit}>
            <div className="col-md-4 mb-3">
                <div className="form-group mb-2">
                    <label htmlFor="name">Name</label>
                    <input required className="form-control"
                        type="text"
                        name="name"
                        id="name"
                        defaultValue={response.name}
                        onChange={handleOnChange}
                    />
                </div>
                <div className="form-group mb-2">
                    <input className="form-check-input"
                        type="checkbox"
                        name="is_event"
                        id="is_event"
                        defaultChecked={response.is_event}
                        onChange={onCheckChange}
                    />
                    <label htmlFor="is_event" className="ms-1">Event</label>
                </div>
                <div className="form-group mb-2">
                    <input className="form-check-input"
                        type="checkbox"
                        name="is_organization"
                        id="is_organization"
                        defaultChecked={response.is_organization}
                        onChange={onCheckChange}
                    />
                    <label htmlFor="is_organization" className="ms-1">Organization</label>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="file-upload-svg">Upload Svg</label>
                    <input className="form-control"
                        type="file"
                        name="file-upload-svg"
                        id="file-upload-svg"
                        onChange={onFileChange}
                        accept=".svg"
                    />
                    {icon && icon.url && (
                        <img src={`https://${icon.url}`} alt="Current SVG icon" style={{ maxWidth: '100px', marginTop: '10px', display: 'block', minWidth: '100px' }} />
                    )}
                </div>
            </div>
            <div className="col-md-4 mb-3">
                <div className="form-group">
                    <button type="submit" className="btn btn-primary" >Submit</button>
                </div>
            </div>
        </form>

        </div>
    )
}

export default EditCategoryform;