import {useEffect, useState} from 'react';
import TextField from "../../fragments/form/TextField"
import CheckboxSelector from "../../fragments/form/CheckboxSelector"
import SubmitButton from "../../fragments/form/SubmitButton"
import {useParams} from "react-router-dom";

const EditCountryForm = (props) => {
    const [formData, setFormData] = useState(new FormData());

    const onChange = (id, value) => {
        formData.set(id, value);
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(formData);
    }
   
    return (
        <form onSubmit={onSubmit}>
            <TextField id="country" required={true} value={props.country.country} onChange={onChange} label="Name" />
            <TextField id="country_code" required={true} value={props.country.country_code} onChange={onChange} label="Country code" />
            <CheckboxSelector id="is_published" value={props.country.is_published} onChange={onChange} label="Publish" />
            <SubmitButton />
        </form>
    );
}

export default EditCountryForm;
