import {useEffect,useState} from 'react';
import {useLocation} from "react-router-dom";
import DashboardHeader from '../../components/DashboardHeader';
import '../styles.css';
import CreateCategoryform from "./create-form";

function Newcategory(props) {

    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="Back" link="/categories" />

            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Create New Category </h2>
                </div>
                <CreateCategoryform token={props.token}/>
            </div>
        </div>
    );
}

export default Newcategory;