import React, {useState} from "react"
import {updateSponsor} from "../../services/sponsors";


function EditSponsorform(props){
    console.log(props);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const single = props.sponsor

    const [response, setResponse] = useState({
        "name": single.name,
        "image_url": single.image_url,
        "sponsor_url": single.sponsor_url,
        "sponsor_type": single.sponsor_type,

    })
    const handleOnChange = e => {

        setResponse({...response, [e.target.name]: e.target.value})
    }

    function updateSingleSponsor(id,body){
        updateSponsor(id,body,props.token).then((response) => {
            setData(response.data);
        })
            .catch((err) => {
                console.log(err.message);
                setError(err.message)
            });
    }

    const handleOnSubmit = e => {
        e.preventDefault();
        updateSingleSponsor(single.id,{
            "name": response.name,
            "image_url": response.image_url,
            "sponsor_url": response.sponsor_url,
            "sponsor_type": response.sponsor_type,

        })
    }


    return(
        <div className="card-body">
            {error ? <div className="alert alert-danger" role="alert">
                {error}
            </div>: null }
            {data ? <div className="alert alert-success" role="alert">
              Sponsor with ID  {single.id} has been edited
            </div>: null }


        <form onSubmit={handleOnSubmit}>
            <div className="col-md-4 mb-3">
            <label htmlFor="name">Name</label>
            <div className="form-group">
                <input required className="form-control"
                    type="text"
                    name="name"
                       defaultValue={response.name}
                       onChange={handleOnChange}
                />
            </div>
            </div>
            <div className="col-md-4 mb-3">
                <label htmlFor="image_url">Image Url</label>
                <div className="form-group">
                    <input required className="form-control"
                           type="text"
                           name="image_url"
                           defaultValue={response.image_url}
                           onChange={handleOnChange}
                    />
                </div>
            </div>
            <div className="col-md-4 mb-3">
                <label htmlFor="sponsor_url">Sponsor Url</label>
                <div className="form-group">
                    <input required className="form-control"
                           type="text"
                           name="sponsor_url"
                           defaultValue={response.sponsor_url}
                           onChange={handleOnChange}
                    />
                </div>
            </div>
            <div className="col-md-4 mb-3">
                <label htmlFor="sponsor_type">Sponsor Type</label>
                <div className="form-group">
                    <select required className="form-select"
                            name="sponsor_type"
                            onChange={handleOnChange}
                            defaultValue={response.sponsor_type}
                    >
                        <option>Select Type</option>
                        <option value="GOLD">Gold Sponsor</option>
                        <option value="SILVER">Silver Sponsor</option>
                        <option value="THANK">Special Thank you</option>
                    </select>
                </div>
            </div>
            <div className="col-md-4 mb-3">

                <div className="form-group">
                    <button type="submit" className="btn btn-primary" >Submit</button>
                </div>
            </div>
        </form>

        </div>
    )
}

export default EditSponsorform;