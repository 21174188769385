import React, {useState} from "react"
import {Link} from 'react-router-dom';
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import {deleteBlogpost} from "../../services/blogposts";

import {PopupboxManager, PopupboxContainer} from 'react-popupbox';
function Blogtables(props){
    const [data, setData] = useState('');
    const [error, setError] = useState('');

    const popupboxConfig = {
        titleBar: {
            enable: true,
            text: 'Please confirm'
        },
        overlayClose:true,
        fadeIn: true,
        fadeInSpeed: 500
    }

    function deleteBlog(id){
        deleteBlogpost(id,props.token).then((response) => {
            setData(response.data);
        })
            .catch((err) => {
                console.log(err.message);
                setError(err.message)
            });
        PopupboxManager.close()
    }


    function openPop(id){
        const content = (
            <div>
                <h4>are you sure to delete blog post id : {id} ? </h4>
                <row>
                    <button onClick={() => deleteBlog(id)} className="btn btn-danger warning-btn"> Delete </button>
                    <button onClick={PopupboxManager.close}  className="btn btn-warning warning-btn"> Cancel</button>
                </row>
            </div>
        )
        PopupboxManager.open({ content:content, config: popupboxConfig})
    }

    return(
        <div>
            <PopupboxContainer/>
            {error ? <div className="alert alert-danger" role="alert">
                {error}
            </div> : null}
            {data ? <div className="alert alert-success" role="alert">
                Blog post with ID {data.data.blogpost.id} has been deleted
            </div> : null}
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>CREATED AT</th>
                        <th>PUBLISH</th>
                        <th>TITLE</th>
                        <th>BODY</th>
                        <th>CREATED BY</th>
                        <th>DELETED BY</th>
                        <th>EVENT</th>
                        <th>ORGANIZATION</th>
                        <th>ACTIONS</th>
                    </tr>
                </thead>

                {props.blogs.length !== 0 ?
                    <tbody>
                    {props.blogs.map((blog, index) => (
                        <tr key={index}>
                            <td><span>{blog.id}</span></td>
                            <td><span>{blog.created_at}</span></td>
                            <td>
                                <div>
                                    {blog.is_published === true ?
                                        <img
                                            src={DoneIcon}
                                            alt='paid-icon'
                                            className='dashboard-content-icon'/>
                                        : blog.is_published === false ?
                                            <img
                                                src={CancelIcon}
                                                alt='canceled-icon'
                                                className='dashboard-content-icon'/>
                                            : null}
                                    <span>{blog.is_published}</span>
                                </div>
                            </td>
                            {/*<td>
                                        <div>
                                            <img
                                                src={order.avatar}
                                                className='dashboard-content-avatar'
                                                alt={order.first_name + ' ' +order.last_name} />
                                            <span>{order.first_name} {order.last_name}</span>
                                        </div>
                                    </td>*/}
                            <td><span>{blog.title}</span></td>
                            <td><span>{blog.body}</span></td>
                            <td><span>{blog.created_by}</span></td>
                            <td><span>{blog.deleted_by}</span></td>
                            <td><span>{blog.event_id}</span></td>
                            <td><span>{blog.organization_id}</span></td>
                            <td><span><Link to="/singleblog" state={{id: blog.id}}><i
                                className="bi bi-pen">edit</i></Link><br/>
                            <i onClick={() => openPop(blog.id)} className="bi bi-trash">delete</i>
                            </span></td>
                        </tr>
                    ))}
                    </tbody>
                    : null}
            </table>
        </div>
    )

}

export default Blogtables