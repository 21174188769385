import React, {useState, useEffect, useReducer} from "react"
import MunicipalityApi from "../../services/municipalities";
import StateApi from "../../services/states";
import CountryApi from "../../services/countries"
import {useParams} from "react-router-dom";
import DashboardHeader from '../../components/DashboardHeader';
import { PopupboxContainer } from 'react-popupbox';
import Loading from "../../fragments/Loading"
import OrganizationsTable from "../../tables/MunicipleHierarchy/OrganizationTableByCity";
import Pagination from "../../components/Pagination"
import LetterFilter from "../../components/LetterFilter";


function MunicipalityPage(props) {
    const [municipality, setMunicipality] = useState(null);
    const [cityName, setCityName] = useState('');
    const [countryName, setCountryName] = useState('');
    const [stateName, setStateName] = useState('');
    const [organizations, setOrganizations] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [pageItemActive, setPageItemActive] = useState(1);
    const [sortByName, setSortByName] = useState(''); 
    const [sortById, setSortById] = useState('ASC');
    const [search, setSearch] = useState('');
    const [activeLetter, setActiveLetter] = useState('all'); 

    const municipalityApi = new MunicipalityApi(props.token);
    const countryApi  = new CountryApi(props.token)
    const stateApi = new StateApi(props.token)
    const { countryId, stateId ,municipalityId } = useParams();

    const fetchOrganizations = async (search, cityName, page, activeLetter) => {
        try {
            if (cityName) {
                let response;
                if (search) {
                     response = await municipalityApi.searchOrganizationsByName(cityName, page, search);
                }  
                 else if (activeLetter && activeLetter !== 'all') {
                    let formatLetter = activeLetter.toLowerCase();
                    response = await municipalityApi.filterOrganizationsByLetter(cityName, formatLetter, page);
                }  
                else {
                    response = await municipalityApi.getOrganizationsByCity(cityName, page);
                    setActiveLetter('all');
                }
                if(response){
                    setOrganizations([...response.organizations]);
                    setTotalPages(response.total_pages)
                }
            } else {
                setOrganizations([]);
                setTotalPages(0);
            }
        } catch (err) {
            console.error(err.message, err.stack);
        }
    };

    useEffect(() => {
        fetchOrganizations(search, cityName, pageItemActive, activeLetter)
    }, [search, cityName, pageItemActive, activeLetter]);

    const getCountryStateAndMunicipalityById = async (countryId, stateId, municipalityId) => {
        try {
            if (countryId) {
                let countryResponse = await countryApi.getById(countryId);
                let newCountryData = countryResponse.data.data.country;
                let newCountryName = newCountryData ? newCountryData.country : '';
                setCountryName(newCountryName);
            } else {
                setCountryName('');
            }
            if (stateId) {
                let stateResponse = await stateApi.getById(stateId);
                let newStateData = stateResponse.data.data.state;
                let newStateName = newStateData ? newStateData.state : '';
                setStateName(newStateName);
            } else {
                setStateName('');
            }

            if(municipalityId){
                let municipalityResponse = await municipalityApi.getById(municipalityId)
                setMunicipality(municipalityResponse);
                setCityName(municipalityResponse.city)
            }else{
                setMunicipality(null)
                setCityName('')
            }
        } catch (error) {
            console.error(error.message, error.stack);
        }
    };

    useEffect(()=>{
        getCountryStateAndMunicipalityById(countryId, stateId, municipalityId)
    },[countryId, stateId, municipalityId])

    const handleSelectPage = (pageNumber)=>{
        setPageItemActive(pageNumber);
    }

    const handleSortByName = async()=>{
        let value = sortByName === 'ASC' ? 'DESC' : 'ASC'
        setSortByName(value)
        setSortById('')
        try {
            let response = await municipalityApi.sortOrganizationsByName(cityName, pageItemActive, value)
            setOrganizations([...response.organizations]);
            setTotalPages(response.total_pages)
        } catch (err) {
            console.error(err.message, err.stack);
        }
    }

    const handleSortById = async()=>{
        let value = sortById === 'ASC' ? 'DESC' : 'ASC'
        setSortById(value)
        setSortByName('')
        try {
            let response = await municipalityApi.sortOrganizationsById(cityName, pageItemActive, value)
            setOrganizations([...response.organizations]);
            setTotalPages(response.total_pages)
        } catch (err) {
            console.error(err.message, err.stack);
        }
    }

    const __handleSearch = (event) => {
        setSearch(event.target.value);
        setActiveLetter('')
    };

    const handleLetterClick = (letter) => {
        setSearch('')
        setPageItemActive(1); 
        if (letter === 'all') {
            setActiveLetter('all');
        } else {
            setActiveLetter(letter);
        }
      };

    if(!municipality) {
        return (<Loading />)
    }
  
    return(
        <div className='dashboard-content'>
        <DashboardHeader
            btnText="New Organization" link={`/country/${countryId}/state/${stateId}/municipality/${municipalityId}/organization/new`} />
        <div className='dashboard-content-container'>
            <div className='dashboard-content-header'>
                <h2>{countryName && stateName && municipality && municipality.city ? `${countryName} - ${stateName} - ${municipality.city}` : ''}</h2>
                <div>
                    <div className='dashboard-content-search'>
                        <input
                            type='text'
                            value={search}
                            placeholder='Search by name..'
                            className='dashboard-content-input'
                            onChange={e => __handleSearch(e)} />
                    </div>
                </div>
            </div>
            <LetterFilter activeLetter={activeLetter} onLetterClick={handleLetterClick}/>
            <div className="organizations-custom-popup">
                <PopupboxContainer />
            </div>
                {
                    organizations.length > 0 ?
                        <div>
                            <OrganizationsTable organizations={organizations} countryId={countryId} stateId={stateId} municipalityId={municipalityId} handleSortByName={handleSortByName} handleSortById={handleSortById} sortByName={sortByName} sortById={sortById} />
                            <Pagination totalPages={totalPages} pageItemActive={pageItemActive} handleSelectItem={handleSelectPage}/>
                        </div>
                        :
                        <div className='dashboard-content-footer'>
                            <span className='empty-table'>No data</span>
                        </div>
                }
        </div>
    </div>
    )
}

export default MunicipalityPage;