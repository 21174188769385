import React, { useState, useEffect } from "react"
import UnpublishedEventsApi from "../../../services/unpublishedEvents";
import Page from "../../Page"
import UnpublishedEventsTable from "../../../tables/UnpublishedEventsTable"
// import DashboardHeader from '../../../components/DashboardHeader';
import { PopupboxContainer } from 'react-popupbox';
import Pagination from "../../../components/Pagination"

function OrganizationsPage(props) {
    const [events, setEvents] = useState([]);
    const unpublishedEventsApi = new UnpublishedEventsApi(props.token);
    const [search, setSearch] = useState('');
    const [searchCity, setSearchCity] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [pageItemActive, setPageItemActive] = useState(1);

    const fetch = () => {
        unpublishedEventsApi.all("Unpublished", pageItemActive).then((response) => {
            setEvents(response.events);
            setTotalPages(response.totalPages)
        })
    }
  
    useEffect(()=>{
        if(search && searchCity){
            unpublishedEventsApi.searchByCity(searchCity, "Unpublished", pageItemActive).then((response) => {
                let filteredData = response.events.filter((event) => event.title.toLowerCase().includes(search.toLowerCase()))
                setEvents([...filteredData]);
                setTotalPages(Math.ceil(filteredData.length / response.perPageItems));
            }).catch((error) => {
                console.error(error);
                setEvents([]);
            });
        }else if(search && !searchCity){
            unpublishedEventsApi.search(search, "Unpublished", pageItemActive).then((response) => {
                setEvents(response.events);
                setTotalPages(response.totalPages)
            }).catch((error) => {
                console.error(error);
                setEvents([]);
            });
        }else if(!search && searchCity){
            unpublishedEventsApi.searchByCity(searchCity, "Unpublished", pageItemActive).then((response) => {
                setEvents(response.events);
                setTotalPages(response.totalPages)
            }).catch((error) => {
                console.error(error);
                setEvents([]);
            });
        }else{
            fetch()
        }
    },[search, searchCity, pageItemActive])

    // Search
    const __handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleSearchByCity =(event)=>{
        let value = event.target.value;
        setSearchCity(value);
    }

    const handleSelectPage = (pageNumber)=>{
      setPageItemActive(pageNumber);
    }

    return (
        <div className='dashboard-content'>
            {/* <DashboardHeader
                btnText="New Organization" link='/organization/new' /> */}
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Unpublished Events</h2>
                    <div>
                        <div className='dashboard-content-search'>
                            <input
                                type='text'
                                value={search}
                                placeholder='Search by name..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearch(e)} />
                        </div>
                        <div className='dashboard-content-search search-city-input'>
                            <input
                                type='text'
                                value={searchCity}
                                placeholder='Search by city..'
                                className='dashboard-content-input'
                                onChange={e => handleSearchByCity(e)} />
                        </div>
                    </div>
                </div>
                <div className="organizations-custom-popup">
                    <PopupboxContainer />
                </div>
                    {
                        events.length > 0 ?
                            <UnpublishedEventsTable events={events} {...props} refresh={fetch} />
                            :
                            <div className='dashboard-content-footer'>
                                <span className='empty-table'>No data</span>
                            </div>
                    }
                <Pagination totalPages={totalPages} pageItemActive={pageItemActive} handleSelectItem={handleSelectPage}/>
            </div>
        </div>
    )
}

export default OrganizationsPage