
import {useParams, useNavigate} from "react-router-dom";
import React, {useState } from 'react';
import Page from "../Page";
import ParticipantRoleApi from "../../services/participantRole";

import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"
import CreateEventParticipantRoleForm from "../../forms/CreateEventParticipantRoleForm"

function CreateParticipantPage(props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const participantRoleApi = new ParticipantRoleApi(props.token)

    const { organizationId, eventId } = useParams();
    const navigate = useNavigate();

    const onSubmit = (formData) => {
        console.log('formData',formData)
        participantRoleApi.create(formData).then((response) => {
            navigate(`/organization/${organizationId}/event/${eventId}/participant/roles`);
        })
        .catch((err) => {
            console.error(err.message);
            setError(err.message)
            setMessage("");
        });
    }

    return (
        <Page
            buttonText="Back"
            buttonUrl={`/organization/${organizationId}/event/${eventId}/participant/roles`}
            header={`Create Participant Role`}
        >
            <Error error={error} />
            <Message message={message} />
            <CreateEventParticipantRoleForm 
             {...props}
             onSubmit={onSubmit}
            />
        </Page>
    );
}

export default CreateParticipantPage;