import React from 'react';
import './styles.css';

function LetterFilter({ activeLetter, onLetterClick }) {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

    return (
        <div className="alphabet-filter d-flex flex-wrap">
            <button
                onClick={() => onLetterClick('all')}
                className={`alphabet-filter-button btn btn-sm me-2 mb-2 ${activeLetter === 'all' ? 'btn-secondary' : 'btn-outline-secondary'}`}
            >
                All
            </button>
            {letters.map((letter) => (
                <button
                    key={letter}
                    onClick={() => onLetterClick(letter)}
                    className={`alphabet-filter-button btn btn-sm me-2 mb-2 ${activeLetter === letter ? 'btn-secondary' : 'btn-outline-secondary'}`}
                >
                    {letter}
                </button>
            ))}
        </div>
    );
}


export default LetterFilter;
