import axios from "axios";

import API from './api'

const { REACT_APP_API_URI } = process.env;

class CategoryApi {
    constructor(token) {
        this.api = new API(token);
        this.baseUrl = `${REACT_APP_API_URI}/admin/category`
    }

    all() {
        try {
            return this.api.get(this.baseUrl)
                .then((response) => {
                    return response.data.data.categories;
                });
        } catch (error) {
            console.error(`Error listing categories. error=${error}`);
            return []
        }
    }

    getById(id) {
        try {
            return this.api.get(`${this.baseUrl}/${id}`)
                .then((response) => {
                    return response.data.data;
                });
        } catch (error) {
            console.error(`Error fetching category. id=${id}, error=${error}`);
            return {}
        }
    }

    update(id, body) {
        try {
            const data = new FormData()
            if(body.icon_file?.name){
                data.append('icon_file', body.icon_file)
            }
            data.append('name', body.name);
            data.append('is_event', body.is_event ? 1 : 0);
            data.append('is_organization', body.is_organization ? 1 : 0);
            return this.api.patch(`${this.baseUrl}/${id}`, data, "multipart/form-data")
                .then((response) => {
                    return response.data.data.category;
                });
        } catch (error) {
            console.error(`Error updating category. id=${id}, error=${error}`);
            return {}
        }
    }

    create(body) {
        try {
            const data = new FormData()
            data.append('icon_file', body.icon_file)
            data.append('name', body.name);
            data.append('is_event', body.is_event ? 1 : 0);
            data.append('is_organization', body.is_organization ? 1 : 0);
            return this.api.post(`${this.baseUrl}`, 
            data
            , "multipart/form-data")
                .then((response) => {
                    return response.data;
                });
        } catch (error) {
            console.error(`Error creating category. error=${error}`);
            return {}
        }
    }

    delete(id) {
        try {
            return this.api.delete(`${this.baseUrl}/${id}`)
                .then((response) => {
                    return response.data.data;
                });
        } catch (error) {
            console.error(`Error deleting category. error=${error}`);
            return {}
        }
    }
}

export default CategoryApi;