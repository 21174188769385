import React, {useState} from "react"
import {updateSlide} from "../../services/slides";


function EditSlideform(props){
    console.log(props);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const single = props.slide

    const [response, setResponse] = useState({
        "title": single.title,
        "head_title": single.head_title,
        "subtitle": single.subtitle,
        "button1_content": single.button1_content,
        "button2_content": single.button2_content,
        "button1_url": single.button1_url,
        "button2_url": single.button2_url,
        "image_url": single.image_url,

    })
    const handleOnChange = e => {

        setResponse({...response, [e.target.name]: e.target.value})
    }

    function updateSingleSlide(id,body){
        updateSlide(id,body,props.token).then((response) => {
            setData(response.data);
        })
            .catch((err) => {
                console.log(err.message);
                setError(err.message)
            });
    }

    const handleOnSubmit = e => {
        e.preventDefault();
        updateSingleSlide(single.id,{
            "title": response.title,
            "head_title": response.head_title,
            "subtitle": response.subtitle,
            "button1_content": response.button1_content,
            "button2_content": response.button2_content,
            "button1_url": response.button1_url,
            "button2_url": response.button2_url,
            "image_url": response.image_url
        })
    }


    return(
        <div className="card-body">
            {error ? <div className="alert alert-danger" role="alert">
                {error}
            </div>: null }
            {data ? <div className="alert alert-success" role="alert">
              Slide with ID  {single.id} has been edited
            </div>: null }


        <form onSubmit={handleOnSubmit}>
            <div className="col-md-4 mb-3">
            <label htmlFor="head_title"> Header Title</label>
            <div className="form-group">
                <input required className="form-control"
                    type="text"
                    name="head_title"
                       defaultValue={response.head_title}
                       onChange={handleOnChange}
                />
            </div>
            </div>
            <div className="col-md-4 mb-3">
                <label htmlFor="title">Title</label>
                <div className="form-group">
                    <input required className="form-control"
                           type="text"
                           name="title"
                           defaultValue={response.title}
                           onChange={handleOnChange}
                    />
                </div>
            </div>
            <div className="col-md-4 mb-3">
                <label htmlFor="subtitle">Subtitle</label>
                <div className="form-group">
                    <input required className="form-control"
                           type="text"
                           name="subtitle"
                           defaultValue={response.subtitle}
                           onChange={handleOnChange}
                    />
                </div>
            </div>

                <div className="col-md-4 mb-3">
                    <label htmlFor="button1_content">First Button Content</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="button1_content"
                               defaultValue={response.button1_content}
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label htmlFor="button1_url">First Button Url</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="button1_url"
                               defaultValue={response.button1_url}
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label htmlFor="button2_content">Second Button Content</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="button2_content"
                               defaultValue={response.button2_content}
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label htmlFor="button2_url">Second Button Url</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="button2_url"
                               defaultValue={response.button2_url}
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label htmlFor="image_url">Image Url</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="image_url"
                               defaultValue={response.image_url}
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
            <div className="col-md-4 mb-3">

                <div className="form-group">
                    <button type="submit" className="btn btn-primary" >Submit</button>
                </div>
            </div>
        </form>

        </div>
    )
}

export default EditSlideform;