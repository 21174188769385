import React, {useState} from 'react';
import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

import TextValue from "../../fragments/table/TextValue"
import {Link} from "react-router-dom";

function CountryHierarchyTable (props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);

    return(
        <div>
            <Error message={error} />
            <Message message={message} />
            <table>
                <tbody>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>STATUS</th>
                        <th>ACTIONS</th>
                    </tr>

                    {props.countries.map((country, index) => (
                        <tr key={index}>
                            <td><TextValue value={country.id} /></td>
                            <td><TextValue value={country.country} /></td>
                            <td><TextValue value={country.is_published ? 'Published': 'Pending'} /></td>
                            <td>
                                <span>
                                    <Link to={`/country/${country.id}/states`}>
                                        <i className="bi">View</i>
                                    </Link>
                                    <br />
                                    <Link to={`/country/${country.id}/edit`}>
                                        <i className="bi bi-pen">Edit</i>
                                    </Link>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default CountryHierarchyTable;