import API from './api'

const {REACT_APP_API_URI} = process.env;


class StateApi {
    constructor(token) {
        this.api = new API(token);
        this.baseUrl = `${REACT_APP_API_URI}/admin/state`
    }

    all(countryId,pageNumber) {
        try {
            return this.api.get(`${REACT_APP_API_URI}/admin/country/${countryId}/states?page_num=${pageNumber}`)
            .then((response) => {
                return {
                    countries: Object.values(response.data.data.countries),
                    states: Object.values(response.data.data.states),
                    total_pages: response.data.data.total_pages
                }
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    search(countryId, state, pageNumber) {
        try {
            return this.api.get(`${REACT_APP_API_URI}/admin/country/${countryId}/states?page_num=${pageNumber}&state=${state}`)
            .then((response) => {
                return {
                    states: Object.values(response.data.data.states),
                    total_pages: response.data.data.total_pages
                }
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    getById(id) {
        try {
            return this.api.get(`${this.baseUrl}/${id}`)
            .then((response) => {
                return response
            });
        } catch(error) {
            console.error(`Error fetching state. id=${id}, error=${error}`);
            return {}
        }
    }

    update(id, body) {
        try{
            return this.api.patch(`${this.baseUrl}/${id}`, body, "multipart/form-data")
            .then((response) => {
                return response;
            });
        }catch(error) {
            console.error(`Error updating state. id=${id}, error=${error}`);
            return {}
        }
    }

    create(body) {
        try{
            return this.api.post(this.baseUrl, body, "multipart/form-data")
            .then((response) => {
                return response;
            });
        }catch(error) {
            console.error(`Error creating state. error=${error}`);
            return {}
        }
    }

    getlist(countryId,maxStates){
        try {
            return this.api.get(`${REACT_APP_API_URI}/admin/country/${countryId}/states?max_states=${maxStates}`)
            .then((response) => {
                return Object.values(response.data.data.states)
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    getStatesByName(countryId,state) {
        try {
            return this.api.get(`${REACT_APP_API_URI}/admin/country/${countryId}/states?state=${state}`)
                .then((response) => {
                    return Object.values(response.data.data.states)
                });
        } catch (error) {
            console.error(error);
            return []
        }
    }

    searchStateById(id) {
        try {
            return this.api.get(`${this.baseUrl}/${id}`)
            .then((response) => {
                return  {
                    states: Object.values(response.data.data),
                    total_pages: 1
                }
            });
        } catch(error) {
            console.error(`Error fetching state. id=${id}, error=${error}`);
            return {}
        }
    }
    
    filterByLetter(countryId, value, pageNumber){
        try {
            let letter = value.toLowerCase()
            return this.api.get(`${REACT_APP_API_URI}/admin/country/${countryId}/states?state_start=${letter}&page_num=${pageNumber}`)
                .then((response) => {
                    return {
                        states: Object.values(response.data.data.states),
                        total_pages: response.data.data.total_pages
                    }
                });
        } catch (error) {
            console.error(error);
            return []
        }
    }

    getStateListByLetter(countryId, value){
        try {
            let letter = value.toLowerCase()
            return this.api.get(`${REACT_APP_API_URI}/admin/country/${countryId}/states?state_start=${letter}`)
                .then((response) => {
                    return {
                        states: Object.values(response.data.data.states),
                        total_pages: response.data.data.total_pages
                    }
                });
        } catch (error) {
            console.error(error);
            return []
        }
    }
}

export default StateApi;