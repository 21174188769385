import React, {useState} from "react"
import {Link} from "react-router-dom";
import DoneIcon from '../../assets/icons/done.svg';
import CancelIcon from '../../assets/icons/cancel.svg';
import RefundedIcon from '../../assets/icons/refunded.svg';
import {PopupboxManager, PopupboxContainer} from 'react-popupbox';
import {deleteSponsor} from "../../services/sponsors";

function SponsorTables(props){
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const popupboxConfig = {
        titleBar: {
            enable: true,
            text: 'Please confirm'
        },
        overlayClose:true,
        fadeIn: true,
        fadeInSpeed: 500
    }

    function deleteSingleSponsor(id){
        deleteSponsor(id,props.token).then((response) => {
            setData(response.data);
        })
            .catch((err) => {
                console.log(err.message);
                setError(err.message)
            });
        PopupboxManager.close()
    }


    function openPop(id){
        const content = (
            <div>
                <h4>are you sure to delete Sponsor id : {id} ? </h4>
                <row>
                    <button onClick={() => deleteSingleSponsor(id)} className="btn btn-danger warning-btn"> Delete </button>
                    <button onClick={PopupboxManager.close}  className="btn btn-warning warning-btn"> Cancel</button>
                </row>
            </div>
        )
        PopupboxManager.open({ content:content, config: popupboxConfig})
    }
    return(
        <div>
            <PopupboxContainer/>
            {error ? <div className="alert alert-danger" role="alert">
                {error}
            </div> : null}
            {data ? <div className="alert alert-success" role="alert">
                Sponsor with ID {data.data.sponsor.id} has been deleted
            </div> : null}
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>TYPE</th>
                        <th>ACTIONS</th>
                    </tr>
                </thead>

                {props.sponsor.length !== 0 ?
                    <tbody>
                    {props.sponsor.map((sponsor, index) => (
                        <tr key={index}>
                            <td><span>{sponsor.id}</span></td>

                            {/*<td>
                                        <div>
                                            <img
                                                src={order.avatar}
                                                className='dashboard-content-avatar'
                                                alt={order.first_name + ' ' +order.last_name} />
                                            <span>{order.first_name} {order.last_name}</span>
                                        </div>
                                    </td>*/}
                            <td><span>{sponsor.name}</span></td>
                            <td><span>{sponsor.sponsor_type}</span></td>
                            <td><span><Link to="/singlesponsor" state={{id: sponsor.id}}><i
                                className="bi bi-pen">edit</i></Link><br/>
                            <i onClick={() => openPop(sponsor.id)} className="bi bi-trash">delete</i>
                            </span></td>
                        </tr>
                    ))}
                    </tbody>
                    : null}
            </table>
        </div>
    )

}

export default SponsorTables