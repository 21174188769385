import TextPanelItem from "../fragments/panel/TextPanelItem"
import BoolPanelItem from "../fragments/panel/BoolPanelItem"
import ImagePanelItem from "../fragments/panel/ImagePanelItem"
import Panel from "../fragments/panel/Panel"
import { Fragment } from "react"
import placeholder from "../assets/images/placeholder.png"

const OrganizationPanel = (props) => (
    <Fragment>
        {
            props.organization.is_corkboard ? 
        <Panel>
            <TextPanelItem value={props.organization.id} label="ID" />
            <TextPanelItem value={props.organization.name} label="Name" />
            <BoolPanelItem value={props.organization.is_published} label="Published?" />
        </Panel>
        :
        <Panel>
            <TextPanelItem value={props.organization.id} label="ID" />
            <TextPanelItem value={props.organization.name} label="Name" />
            <TextPanelItem value={props.organization.description} label="Description" />
            <TextPanelItem value={props.organization.website_url} label="Website" />
            <TextPanelItem value={props.organization.twitter_name} label="Twitter Name" />
            <TextPanelItem value={props.organization.facebook_name} label="Facebook Name" />
            <TextPanelItem value={props.organization.instagram_name} label="Instagram Name" />
            <TextPanelItem value={props.organization.linkedin_name} label="Linkedin Name" />
            <TextPanelItem value={props.organization.email_address} label="Email" />
            <TextPanelItem value={props.organization.phone_number} label="Phone Number" />
            <TextPanelItem value={props.location?.address_1 || ''} label="Address 1" />
            <TextPanelItem value={props.location?.address_2 || ''} label="Address 2" />
            <TextPanelItem value={props.location?.country || ''} label="Country" />
            <TextPanelItem value={props.location?.state || ''} label="State" />
            <TextPanelItem value={props.location?.city || ''} label="City" />
            <TextPanelItem value={props.location?.zip || ''} label="Zip" />
            <TextPanelItem value={props.location?.longitude || ''} label="Longitude" />
            <TextPanelItem value={props.location?.latitude || ''} label="Latitude" />
            <ImagePanelItem url={props.organization.card_image?.url || placeholder} label="Card Image" />
            <ImagePanelItem url={props.organization.profile_image?.url || placeholder} label="Profile Image" />
            <BoolPanelItem value={props.organization.is_published} label="Published?" />
        </Panel>
        }
    </Fragment>
)

export default OrganizationPanel;