import React from "react";
import SideBar from "../../components/Sidebar";
import sidebar_menu from "../../constants/sidebar-menu";

function Layout({ children }) {
    return (
        <>
            {/* all child componnet under the layout */}
            <div className='dashboard-container'>
                <SideBar menu={sidebar_menu} />

                <div className='dashboard-body'>
               {children}
                </div>
            </div>
        </>
    );
}

export default Layout;