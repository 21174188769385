
import YesIcon from '../../assets/icons/done.svg';
import NoIcon from '../../assets/icons/cancel.svg';

import PanelItem from './PanelItem'

const BoolPanelItem = (props) => (
    <PanelItem {...props}>
        {props.value ? "Yes" : "No"}
        {props.value ?
            <img
                src={YesIcon}
                className='dashboard-content-icon'/>
            : <img
                src={NoIcon}
                className='dashboard-content-icon'/>
        }
    </PanelItem>
)

export default BoolPanelItem;