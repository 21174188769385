import React, {useState, useEffect} from "react"
import OrganizationApi from "../../services/organizations";
import LocationApi from "../../services/locations";
import {useParams} from "react-router-dom";

import OrganizationPanel from "../../panels/OrganizationPanel"
import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav"
import OrganizationLocationHeaderNav from "../../fragments/nav/OrganizationLocationHeaderNav"

import Page from "../Page"
import Loading from "../../fragments/Loading"

function OrganizationPage(props) {
    const [organization, setOrganization] = useState(null);
    const [location, setLocation] = useState(null)
    const organizationApi = new OrganizationApi(props.token);
    const locationApi = new LocationApi(props.token);
    const routeParams = useParams();

    const fetchOrganizationAndLocation = async (organizationId) => {
        try {
            const organizationResponse = await organizationApi.getById(organizationId);
            setOrganization(organizationResponse);
            if (organizationResponse.location_id) {
                const locationResponse = await locationApi.getById(organizationResponse.location_id);
                setLocation(locationResponse); 
            } else {
                setLocation(null); 
            }
        } catch (err) {
            console.error(err.message, err.stack);
        }
    };

    useEffect(() => {
        fetchOrganizationAndLocation(routeParams.organizationId)
    },[routeParams.organizationId]);

    const handleBack=()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId){
            return  `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}`   
        }else{
            return '/organizations'
        }
    }

    if(!organization) {
        return (<Loading />)
    }
    return(
        <Page 
            buttonText="Back"
            buttonUrl={handleBack()}
            header={`Organization: ${organization.name}`}
        >
            {routeParams.countryId && routeParams.stateId && routeParams.municipalityId && routeParams.organizationId ?
                <OrganizationLocationHeaderNav countryId={routeParams.countryId} stateId={routeParams.stateId} municipalityId={routeParams.municipalityId} organizationId={routeParams.organizationId} />
                :
                <OrganizationHeaderNav organizationId={organization.id}/>
            }
            <OrganizationPanel organization={organization} location={location}/>
        </Page>
    )
}

export default OrganizationPage;