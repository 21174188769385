import HeaderNav from './HeaderNav'
import HeaderNavItem from './HeaderNavItem'

const OrganizationNavHeader = (props) => (
    <HeaderNav>
        <HeaderNavItem location={`/organization/${props.organizationId}`} label="Overview" />
        <HeaderNavItem location={`/organization/${props.organizationId}/edit`} label="Edit" />
        <HeaderNavItem location={`/organization/${props.organizationId}/locations`} label="Locations" />
        <HeaderNavItem location={`/organization/${props.organizationId}/events`} label="Events" />
        {
            props.isShowArchive  &&  <HeaderNavItem location={`/organization/${props.organizationId}/archive`} label="Archive" />
        }
    </HeaderNav>
)

export default OrganizationNavHeader;