import {useEffect,useState} from 'react';
import {useLocation} from "react-router-dom";
import DashboardHeader from '../../components/DashboardHeader';
import '../styles.css';
import CreateSlideform from "./create-form";

function NewSlide(props) {

    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="Back" link="/slides" />

            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Create New Slide </h2>



                </div>
                <CreateSlideform token={props.token}/>
            </div>
        </div>
    );
}

export default NewSlide;