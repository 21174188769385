import React, {useState, useEffect} from "react"
import ParticipantRoleApi from "../../services/participantRole";
import {useParams} from "react-router-dom";

import Page from "../Page"
import Loading from "../../fragments/Loading"
import Panel from "../../fragments/panel/Panel"
import TextPanelItem from "../../fragments/panel/TextPanelItem"

function ParticipantRolePage(props) {
    const [role, setRole] = useState(null);
    const participantRoleApi = new ParticipantRoleApi(props.token);
    const { organizationId, eventId, roleId } = useParams();

    const fetch = () => {
        participantRoleApi.getById(roleId).then((response) => {
            setRole(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    }

    useEffect(() => {
        fetch()
    }, []);

    if(!role) {
        return (<Loading />)
    }

    return(
        <Page 
            buttonText="Back"
            buttonUrl={`/organization/${organizationId}/event/${eventId}/participant/roles`}
            header={`Role: ${role? role.name: ""}`}
        >
            <Panel>
                <TextPanelItem value={role.id} label="ID" />
                <TextPanelItem value={role.name} label="Name" />
            </Panel>
        </Page>
    )
}

export default ParticipantRolePage;