import axios from "axios";

const {REACT_APP_API_URI} = process.env;

const BaseUrl = `${REACT_APP_API_URI}/blog`


export async function getBlogposts() {
    try{
        return await axios.get(`${BaseUrl}`)
    }catch(error) {
        return[]
    }
}

export function getBlogpost(id) {
    try{
        return axios.get(`${BaseUrl}/${id}`)
    }catch(error) {
        return[]
    }
}

export function updateBlogpost(id,body,token) {
    try{
        return axios.patch(`${BaseUrl}/${id}`,body,{
            headers: { 'Authorization': 'Bearer ' + token }
        })
    }catch(error) {
        return[]
    }
}

export function deleteBlogpost(id,token) {
    try{
        return axios.delete(`${BaseUrl}/${id}`,{
            headers: { 'Authorization': 'Bearer ' + token }
        })
    }catch(error) {
        return[]
    }
}



