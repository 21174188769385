import {useState , useEffect} from 'react';
import FormGroup from '../../fragments/form/FormGroup';
import MuiTextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
      fontFamily: 'inherit', 
    },
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            fontFamily: 'inherit', 
          },
          input: {
            fontFamily: 'inherit',
          },
        },
      },
    },
  });

  const SearchSelector =(props)=>{
    let {id ,label, collection, isSubmitted ,  handleSearchLocation, updateLocationFields, resetLocationFields, prompt, required, location} = props
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState({ label: "", value: "" })

    useEffect(()=>{
        if(location){
            let newData = {}
            if(id === 'country'){
                newData.label = location.country || '' ;
                newData.value = location.country_id || null ;
            }
            else if(id === 'state'){
                newData.label = location.state || '';
                newData.value = location.state_id || null;
            }
            else if(id === 'city'){
                newData.label = location.city || '';
                newData.value = location.city_id || null;
            }else{
                //nothing
            }
            setData(newData)
        }
      
    },[location])

    useEffect(() => {
        if (required && isSubmitted & (!data?.value || !data.value)) {
          setIsError(true); 
        } else {
          setIsError(false); 
        }
      }, [isSubmitted, id, required]);

    return(
        <FormGroup {...props} label={label}>
        <ThemeProvider theme={theme}>
            <Autocomplete
                value={data}
                options={collection}
                getOptionLabel={(option) => option.label || option.country || option.state || option.city || ""}
                onChange={(event, newValue) => {
                    if(newValue){
                        data.label = newValue.label ;
                        data.value = newValue.value ;
                        setData(data)
                        updateLocationFields(id, newValue.label, newValue.value)
                    }else{
                        data.label = '' ;
                        data.value = null ;
                        setData(data)
                        resetLocationFields(id)
                    }
                }}
                sx={{
                    "& .MuiInputBase-root": {
                        padding: '2px'
                    },
                    "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                      padding: "6px 12px", 
                      "&::placeholder": {
                        color: "#212529",
                        opacity: 1,
                      },
                    },
                  }}
                renderInput={(params) => <MuiTextField {...params} 
                sx={{
                    "& .MuiFormHelperText-root": {
                      color: "red", 
                    }
                  }}
                onChange={(event, newValue) => {
                    let countryId = location?.country_id
                    let stateId = location?.state_id
                    handleSearchLocation(id, countryId, stateId, event.target.value)
                }}
                placeholder={prompt}
                error={isError} 
                helperText={isError ? "This field is required." : ""}
                />}
            />
        </ThemeProvider>
    </FormGroup>
    )
  }

  export default SearchSelector