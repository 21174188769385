import React, {useState, useEffect} from 'react';
import DashboardHeader from '../../components/DashboardHeader';

import {calculateRange, sliceData} from '../../utils/table-pagination';

import '../styles.css';
import {getSponsors} from "../../services/sponsors";
import SponsorTables from "./datatables";

function Sponsors (props) {
    const [search, setSearch] = useState('');
    const [sponsor, setSponsor] = useState('');
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);

    useEffect(() => {
        getSponsors().then((response) => {
            setSponsor(sliceData(response.data.data.sponsor, page, 10));
        })
            .catch((err) => {
                console.log(err.message);
            });
        setPagination(calculateRange(sponsor, 10));
    }, []);

    // Search
    const __handleSearch = (event) => {

        setSearch(event.target.value);
        if (event.target.value !== '') {
            let search_results = sponsor.filter((item) =>
                item.name.toLowerCase().includes(search.toLowerCase())
            );
            setSponsor(search_results);
        }
        else {
            __handleChangePage(1);
        }
    };

    // Change Page 
    const __handleChangePage = (new_page) => {
        setPage(new_page);
        setSponsor(sliceData(sponsor, new_page, 10));
    }

    return(
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="New Sponsor" link='/newsponsor' />

            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Sponsors List</h2>
                    <div className='dashboard-content-search'>
                        <input
                            type='text'
                            value={search}
                            placeholder='Search..'
                            className='dashboard-content-input'
                            onChange={e => __handleSearch(e)} />
                    </div>
                </div>

               <SponsorTables token={props.token} sponsor={sponsor}/>

                {sponsor.length !== 0 ?
                    <div className='dashboard-content-footer'>
                        {pagination.map((item, index) => (
                            <span 
                                key={index} 
                                className={item === page ? 'active-pagination' : 'pagination'}
                                onClick={() => __handleChangePage(item)}>
                                    {item}
                            </span>
                        ))}
                    </div>
                : 
                    <div className='dashboard-content-footer'>
                        <span className='empty-table'>No data</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default Sponsors;