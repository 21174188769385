import DashboardIcon from '../assets/icons/dashboard.svg';
import BlogIcon from '../assets/icons2/icons8-field-64.png'
import EventIcon from '../assets/icons2/icons8-schedule-64.png'
import OrganizationIcon from '../assets/icons2/icons8-organization-64.png'
import CategoryIcon from '../assets/icons2/icons8-tools-64.png'
import QuestionIcon from '../assets/icons2/icons8-question-mark-50.png'
import LocationIcon from '../assets/icons2/icons8-location-64.png'
// import SlideIcon from '../assets/icons2/icons8-shop-64.png'
// import SponsorIcon from '../assets/icons2/icons8-omnichannel-64.png'


const sidebar_menu = [
    {
        id: 1,
        icon: DashboardIcon,
        path: '/',
        title: 'Dashboard',
    },
    {
        id: 2,
        icon: OrganizationIcon,
        path: '/organizations',
        title: 'Organizations',
    },
    {
        id: 3,
        icon: LocationIcon,
        path: '/countries',
        title: 'Location',
    },
    {
        id: 4,
        icon: CategoryIcon,
        path: '/categories',
        title: 'Categories',
    },
    // {
    //     id: 4,
    //     icon: SlideIcon,
    //     path: '/slides',
    //     title: 'Web slides',
    // },
    // {
    //     id: 5,
    //     icon: SponsorIcon,
    //     path: '/sponsors',
    //     title: 'Web sponsors',
    // },
    {
        id: 5,
        icon: QuestionIcon,
        path: '/unpublished',
        title: 'Unpublished',
        submenu: [
            {
                id: 1,
                path: '/unpublished/organizations',
                title: 'Organizations',
            },
            {
                id: 2,
                path: '/unpublished/events',
                title: 'Events',
            }
        ],
    },
]

export default sidebar_menu;