import React, {useState} from 'react';
import SponsorApi from "../services/sponsorships";
import Error from "../fragments/form/Error"
import Message from "../fragments/form/Message"

import TextValue from "../fragments/table/TextValue"
import {Link , useParams} from "react-router-dom";
import ConfirmDeletePopup from "../fragments/popup/ConfirmDeletePopup";


function SponsorshipsTable (props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const sponsorApi = new SponsorApi(props.token);
    const routeParams = useParams();
    let { organizationId, eventId , sponsors} = props;

    function deleteSponsor(id, name){
        sponsorApi.delete(id).then((response) => {
            setMessage(`Sponsor "${name}" has been deleted`)
            setError("");
            props.refresh(props.eventId);
        })
        .catch((err) => {
            console.error(err.message);
            setError(err.message);
            setMessage("");
        });
    }
    
    return(
        <div>
            <Error message={error} />
            <Message message={message} />
            <table>
                <tbody>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>ACTIONS</th>
                    </tr>
                    {sponsors.map((sponsor, index) => {
                        return (
                            <tr key={index}>
                                <td><TextValue value={sponsor.sponsor_id} /></td>
                                <td><TextValue value={sponsor.name} /></td>
                                <td>
                                    <span>
                                        {routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId ?
                                            <Link to={`/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}/event/${eventId}/sponsor/${sponsor.sponsor_id}`}>
                                                <i className="bi">View</i>
                                            </Link>
                                            :
                                            <Link to={`/organization/${organizationId}/event/${eventId}/sponsor/${sponsor.sponsor_id}`}>
                                                <i className="bi">View</i>
                                            </Link>
                                        }
                                        <br />
                                        <ConfirmDeletePopup
                                            onConfirm={() => deleteSponsor(sponsor.id, sponsor.name)}
                                            prompt={`Are you sure to delete ${sponsor.name} ?`}
                                            acceptLabel="Delete"
                                        />
                                    </span>
                                </td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default SponsorshipsTable;