
import {useParams, useNavigate} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import Page from "../Page";
import CreateMunicipalityForm from "../../forms/MunicipleHierarchy/CreateMunicipalityForm"
import MunicipalityApi from "../../services/municipalities";
import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

function CreateMunicipalityPage(props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const municipalityApi = new MunicipalityApi(props.token);
    const navigate = useNavigate();
    const {countryId, stateId} = useParams()

    const onSubmit = (formData) => {
        municipalityApi.create(formData).then((response) => {
            navigate(`/country/${countryId}/state/${stateId}/municipalities`)
        })
        .catch((err) => {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        });
    }

    return (
        <Page
            buttonText="Back"
            buttonUrl={`/country/${countryId}/state/${stateId}/municipalities`}
            header={`Create Municipality`}
        >
            <Error error={error} />
            <Message message={message} />
            <CreateMunicipalityForm
                {...props}
                stateId={stateId}
                onSubmit={onSubmit}
                />
        </Page>
    );
}

export default CreateMunicipalityPage;