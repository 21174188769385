import React, {useState} from "react";
import { Link } from 'react-router-dom';
import arrowUp from "../../assets/icons2/icons8-arrow-up-24.png";
import arrowDown from "../../assets/icons2/icons8-arrow-down-24.png";

import './styles.css';

const SideBarItem = ({ item, active }) => {
    const [hover, setHover] = useState(false);
    const [ids,setIds] = useState([6])

    const handleClick = (id) => {
        let isIdExisted = ids.find((e)=> e === id)
        if(!isIdExisted){
            ids.push(id)
            setIds([...ids])
        }else{
            let idsFiltered = ids.filter((e)=> e !== id)
            setIds([...idsFiltered])
        }
    };
  
    return (
        <>
            {
                !item.submenu ?
                    <Link
                        to={item.path}
                        className={active ? 'sidebar-item-active' : 'sidebar-item'} >
                        <img
                            src={item.icon}
                            alt={`icon-${item.icon}`}
                            className='sidebar-item-icon' />
                        <span className='sidebar-item-label'>{item.title}</span>
                    </Link>
                    :
                    <div className="sidebar-item-container">
                        <div className={active ? 'sidebar-item-active ' : 'sidebar-item '} onClick={() => handleClick(item.id)}>
                            <img
                                src={item.icon}
                                alt={`icon-${item.icon}`}
                                className='sidebar-item-icon' />
                            <span className='sidebar-item-label'>{item.title}</span>
                            {
                                ids.find((e) => e === item.id) ?
                                    <img
                                        src={arrowUp}
                                        alt={`arrow-up`}
                                        className="sidebar-item-arrow-icon"
                                    />
                                    :
                                    <img
                                        src={arrowDown}
                                        alt={`arrow-down`}
                                        className="sidebar-item-arrow-icon"
                                    />
                            }

                        </div>
                        {
                            ids.find((e) => e === item.id) ?
                                <div className='sidebar-submenu show'>
                                    {item.submenu.map((subItem, index) => (
                                        <Link key={index} to={subItem.path} className='sidebar-submenu-item'>
                                            <span className='sub-sidebar-item-label'>{subItem.title}</span>
                                        </Link>
                                    ))}
                                </div>
                                :
                                <div></div>
                        }
                    </div>
            }
        </>
    )
}
export default SideBarItem;
