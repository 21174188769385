import React, {useState, useEffect} from 'react';
import DashboardHeader from '../../components/DashboardHeader';

import {calculateRange, sliceData} from '../../utils/table-pagination';
import {getBlogposts} from "../../services/blogposts";
import '../styles.css';

import Blogtables from "./datatables";

function Blogs (props) {
    const [search, setSearch] = useState('');
    const [blogposts, setBlogposts] = useState('');
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);

    useEffect(() => {
        getBlogposts().then((response) => {
            setBlogposts(response.data.data.blogposts);
            console.log(blogposts)
        })
            .catch((err) => {
                console.log(err.message);
            });
        setPagination(calculateRange(blogposts, 10));
        setBlogposts(sliceData(blogposts, page, 10))
    }, []);
    if (!blogposts) return null;
    console.log(blogposts)

    // Search
    const __handleSearch = (event) => {

        setSearch(event.target.value);
        if (event.target.value !== '') {
            let search_results = blogposts.filter((item) =>
                item.title.toLowerCase().includes(search.toLowerCase()) ||
                item.body.toLowerCase().includes(search.toLowerCase())
            );
            setBlogposts(search_results);
        }
        else {
            __handleChangePage(1);
        }
    };

    // Change Page 
    const __handleChangePage = (new_page) => {
        setPage(new_page);
        setBlogposts(sliceData(blogposts, new_page, 10));
    }

    return(
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="New Blog Post" link='' />

            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Blog Posts List</h2>
                    <div className='dashboard-content-search'>
                        <input
                            type='text'
                            value={search}
                            placeholder='Search..'
                            className='dashboard-content-input'
                            onChange={e => __handleSearch(e)} />
                    </div>
                </div>

               <Blogtables token={props.token} blogs={blogposts}/>

                {blogposts.length !== 0 ?
                    <div className='dashboard-content-footer'>
                        {pagination.map((item, index) => (
                            <span 
                                key={index} 
                                className={item === page ? 'active-pagination' : 'pagination'}
                                onClick={() => __handleChangePage(item)}>
                                    {item}
                            </span>
                        ))}
                    </div>
                : 
                    <div className='dashboard-content-footer'>
                        <span className='empty-table'>No data</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default Blogs;