
import {useState} from 'react';
import SubmitButton from "../fragments/form/SubmitButton"
import SearchSelector from '../components/SearchSelector/ParticipantSelect';

function CreateSponsorForm(props) {
    const [formData, setFormData] = useState(new FormData());
    const [isSubmitted, setIsSubmitted] = useState(false);

    const onChange = (id, value) => {
        let formatValue = parseInt(value)
        formData.set(id, formatValue);
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        props.onSubmit(formData)
    }

    return (
        <form onSubmit={onSubmit}>
            <SearchSelector id="sponsor_id" isSubmitted={isSubmitted} required={true} handleSearch={props.handleSearchSponsor} collection={props.organizations} onChange={onChange} label="Sponsor" prompt="Please search and select a sponsor"/>
            <SubmitButton />
        </form>
    );
}

export default CreateSponsorForm;