import API from './api'

const {REACT_APP_API_URI} = process.env;



const updateProperty = (subject, keyProperty, valueProperty, collection, defaultValue) => {
    if(typeof defaultValue == "undefined") {
        defaultValue = null;
    }
    const key = subject[keyProperty];
    if(collection[key]) {
        subject[valueProperty] = collection[key]
    } else {
        subject[valueProperty] = defaultValue;
    }
    return subject
}

const formatEvents = (response) => {
    let events = Object.values(response.data.data.events);
    const users = response.data.data.users;
    const images = response.data.data.images;
    const locations = response.data.data.locations;
    const organizations = response.data.data.organizations;
    const categories = response.data.data.categories;
    return events.map((event) => {
        event = updateProperty(event, "created_by", "created_by", users);
        event = updateProperty(event, "updated_by", "updated_by", users);
        event = updateProperty(event, "deleted_by", "deleted_by", users);
        event = updateProperty(event, "location_id", "location", locations);
        event = updateProperty(event, "organization_id", "organization", organizations);
        event = updateProperty(event, "category_id", "category", categories);
        event = updateProperty(event, "profile_image_id", "profile_image", images);
        event = updateProperty(event, "card_image_id", "card_image", images);
        return event;
    });

}

class UnpublishedEventApi {
    constructor(token) {
        this.api = new API(token);
        this.baseUrl = `${REACT_APP_API_URI}/admin/event`
    }

    all(status, pageNumber) {
        try {
            return this.api.get(`${this.baseUrl}?status=${status}&page_num=${pageNumber}`)
            .then((response) => {
                return {
                    events: formatEvents(response),
                    perPageItems: response.data.data.per_page_items,
                    totalItems: response.data.data.total_items,
                    totalPages: response.data.data.total_pages
                }
            });
        }catch(error) {
            console.error(error);
            return {
                events: [],
                perPageItems: 0,
                totalItems: 0,
                totalPages: 0
            };
        }
    }


    search(param, status, pageNumber) {
        try {
            return this.api.get(`${this.baseUrl}?title=${param}&status=${status}&page_num=${pageNumber}`)
            .then((response) => {
                return {
                    events: formatEvents(response),
                    perPageItems: response.data.data.per_page_items,
                    totalItems: response.data.data.total_items,
                    totalPages: response.data.data.total_pages
                }
            });
        }catch(error) {
            console.error(error);
            return {
                events: [],
                perPageItems: 0,
                totalItems: 0,
                totalPages: 0
            };
        }
    }

    searchByCity(param, status, pageNumber) {
        try {
            return this.api.get(`${this.baseUrl}?city=${param}&status=${status}&page_num=${pageNumber}`)
            .then((response) => {
                return {
                    events: formatEvents(response),
                    perPageItems: response.data.data.per_page_items,
                    totalItems: response.data.data.total_items,
                    totalPages: response.data.data.total_pages
                }
            });
        }catch(error) {
            console.error(error);
            return {
                events: [],
                perPageItems: 0,
                totalItems: 0,
                totalPages: 0
            };
        }
    }

    getById(id) {
        try {
            return this.api.get(`${this.baseUrl}/${id}`)
            .then((response) => {
                return formatEvents(response);
            });
        } catch(error) {
            console.error(`Error fetching event. id=${id}, error=${error}`);
            return {}
        }
    }

    update(id, body) {
        try{
            return this.api.patch(`${this.baseUrl}/${id}`, body, "multipart/form-data")
            .then((response) => {
                return formatEvents(response);
            });
        }catch(error) {
            console.error(`Error updating event. id=${id}, error=${error}`);
            return {}
        }
    }
    delete(id) {
        try{
            return this.api.delete(`${this.baseUrl}/${id}`)
            .then((response) => {
                return response.data.data;
            });
        }catch(error) {
            console.error(`Error deleting event. error=${error}`);
            return {}
        }
    }
     
}

export default UnpublishedEventApi;