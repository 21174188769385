
import {useState, useEffect} from 'react';
import TextField from "../../fragments/form/TextField"
import CheckboxSelector from "../../fragments/form/CheckboxSelector"
import SubmitButton from "../../fragments/form/SubmitButton"


function CreateMunicipalityForm(props) {
    const [formData, setFormData] = useState(new FormData());

    useEffect(()=>{
        formData.set("state_id",props.stateId);
        setFormData(formData);
    },[])

    const onChange = (id, value) => {
        formData.set(id, value);
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(formData)
    }

    return (
        <form onSubmit={onSubmit}>
            <TextField id="city" required={true} onChange={onChange} label="Name" onLoad={() => onChange("city", "")}  />
            <CheckboxSelector id="is_published" required={true} onChange={onChange} label="Publish" onLoad={() => onChange("is_published", "0")} />
            <SubmitButton />
        </form>
    );
}

export default CreateMunicipalityForm;