
import {useParams, useNavigate} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import Page from "../Page";
import CreateEventParticipantForm from "../../forms/CreateEventParticipantForm"
import OrganizationApi from "../../services/organizations";
import ParticipantApi from "../../services/participants";
import ParticipantRoleApi from "../../services/participantRole";

import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

function CreateParticipantPage(props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const [participantRoles, setParticipantRoles] = useState([]);
    const organizationApi = new OrganizationApi(props.token);
    const participantApi = new ParticipantApi(props.token);
    const participantRoleApi = new ParticipantRoleApi(props.token)

    const routeParams = useParams();
    const { organizationId, eventId } = routeParams;
    const navigate = useNavigate();

    
    useEffect(() => {
        participantRoleApi.getRoles().then((participantRoles) => {
            setParticipantRoles([...participantRoles])
        })
        .catch((err) => {
            console.error(err.message);
        });
    }, []);


    const onSubmit = (formData) => {
        let formatValue = parseInt(eventId)
        formData.set("event_id", formatValue);
        participantApi.create(formData).then((response) => {
            if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
                navigate (`/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}/event/${eventId}/participants`)
            }else{
                navigate(`/organization/${organizationId}/event/${eventId}/participants`);
            }
        })
        .catch((err) => {
            console.error(err.message);
            setError(err.message)
            setMessage("");
        });
    }

    const handleSearchParticipant= async(value)=>{
        try {
            let response = await organizationApi.getListByLetter('All',value,0) 
            let arrOrganization = response.organizations.map((e)=>({label: e.name, value: e.id,...e}))
            setOrganizations([...arrOrganization])
        } catch (err) {
            console.error(err.message);
            setError(err.message)
            setMessage("");
        }
      
    }

    const handleBack =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}/event/${eventId}/participants`
        }else{
            return `/organization/${organizationId}/event/${eventId}/participants`
        }
    }

    return (
        <Page
            buttonText="Back"
            buttonUrl={handleBack()}
            header={`Create Participant`}
        >
            <Error error={error} />
            <Message message={message} />
            <CreateEventParticipantForm
                {...props}
                onSubmit={onSubmit}
                organizations={organizations}
                participantRoles={participantRoles}
                handleSearchParticipant={handleSearchParticipant}
                />
        </Page>
    );
}

export default CreateParticipantPage;