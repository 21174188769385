import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import StateApi from "../../services/states";

import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"
import Loading from "../../fragments/Loading";
import EditStateForm from "../../forms/MunicipleHierarchy/EditStateForm";
import Page from "../Page";

const EditStatePage = (props) => {
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [state, setState] = useState();

    const stateApi = new StateApi(props.token);
    const { countryId, stateId } = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        stateApi.getById(stateId).then((response) => {
            setState(response.data.data.state)
        })
    }, [countryId]);

    const onSubmit = (formData) => {
        stateApi.update(stateId, formData).then((response) => {
            navigate(`/country/${countryId}/states`);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        });
    }

    if(!state) {
        return (<Loading />)
    }
    return (
        <Page
            buttonText="Back"
            buttonUrl={`/country/${countryId}/states`}
            header={`Edit Province/State: ${state.state}`}
        >
            <Error message={error} />
            <Message message={message} />
            <EditStateForm 
                {...props}
                state={state}
                onSubmit={onSubmit} />
        </Page>

    );
}

export default EditStatePage;
