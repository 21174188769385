import React, { useState, useEffect } from "react"
import MunicipalityApi from "../../services/municipalities";
import CountryApi from "../../services/countries";
import StateApi from "../../services/states";
import MunicipalityTable from "../../tables/MunicipleHierarchy/MunicipalityTable"
import DashboardHeader from '../../components/DashboardHeader';
import LetterFilter from "../../components/LetterFilter";
import { PopupboxContainer } from 'react-popupbox';
import Pagination from "../../components/Pagination"
import { useParams } from "react-router-dom";


function MunicipalitiesPage(props) {
    const [municipalities, setMunicipalities] = useState([])
    const [countryName, setCountryName] = useState('');
    const [stateName, setStateName] = useState();
    const [search, setSearch] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [pageItemActive, setPageItemActive] = useState(1);
    const [searchId, setSearchId] = useState('');
    const [activeLetter, setActiveLetter] = useState('all'); 
   
    const municipalityApi = new MunicipalityApi(props.token);
    const countryApi = new CountryApi(props.token)
    const stateApi = new StateApi(props.token)

    const {countryId, stateId} = useParams()

    const getMunicipalityList = async (page, search, searchId, activeLetter) => {
        try {
            let response;
            if (searchId) {
                response = await  municipalityApi.searchCityById(searchId);
                setPageItemActive(1);
            } 
            else if (search) {
                response = await municipalityApi.search(stateId,search,pageItemActive)
            } 
            else if (activeLetter && activeLetter !== 'all') {
                let formatLetter = activeLetter.toLowerCase();
                response = await municipalityApi.filterByLetter(stateId, formatLetter, page);
            } 
            else {
                response = await municipalityApi.all(stateId, page);
                setActiveLetter('all');
            }
            if (response) {
                setMunicipalities([...response.cities]);
                setTotalPages(response.total_pages || 1);
            }
        } catch (error) {
            console.error(error.message, error.stack);
        }
    };

    const getCountry = async(countryId)=>{
        try {
           let response = await countryApi.getById(countryId);
           setCountryName(response.data.data.country.country)
        } catch (error) {
            console.error(error.message, error.stack);
        }
    }

    const getState = async(stateId)=>{
        try {
           let response = await stateApi.getById(stateId);
           setStateName(response.data.data.state.state)
        } catch (error) {
            console.error(error.message, error.stack);
        }
    }

    const fetchData =async(countryId, stateId, search, searchId, pageItemActive, activeLetter)=>{
        await getCountry(countryId)
        await getState(stateId)
        await getMunicipalityList(pageItemActive,search,searchId,activeLetter)
    }

    useEffect(()=>{
        fetchData(countryId, stateId, search, searchId, pageItemActive, activeLetter)
    },[ countryId, stateId, search, searchId, pageItemActive,activeLetter])

    const __handleSearch = (event) => {
        setSearch(event.target.value);
        setActiveLetter('')
    };

    const __handleSearchById = (event) => {
        setSearchId(event.target.value);
        setActiveLetter('')
    };

    const handleSelectPage = (pageNumber)=>{
        setPageItemActive(pageNumber);
    }
    
    const handleLetterClick = (letter) => {
        setSearch('')
        setSearchId('')
        setPageItemActive(1); 
        if(letter === 'all'){
            setActiveLetter('all'); 
        }else{
            setActiveLetter(letter); 
        }
      };

   
    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="New Municipality" link={`/country/${countryId}/state/${stateId}/municipality/new`} />
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    {countryName && stateName ? <h2 className="municipality-title">{countryName} - {stateName}</h2> : <h2>Cities</h2>}
                    <div>
                        <div className='dashboard-content-search me-2'>
                            <input
                                type='text'
                                value={searchId}
                                placeholder='Search by id..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearchById(e)} />
                        </div>
                        <div className='dashboard-content-search'>
                            <input
                                type='text'
                                value={search}
                                placeholder='Search by name..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearch(e)} />
                        </div>
                    </div>
                </div>
                <LetterFilter activeLetter={activeLetter} onLetterClick={handleLetterClick}/>
                <div className="organizations-custom-popup">
                    <PopupboxContainer />
                </div>
                    {
                        municipalities.length > 0 ?
                        <div>
                            <MunicipalityTable municipalities={municipalities} {...props} refresh={fetch} />
                            <Pagination totalPages={totalPages} pageItemActive={pageItemActive} handleSelectItem={handleSelectPage}/>
                         </div>
                            :
                            <div className='dashboard-content-footer'>
                                <span className='empty-table'>No data</span>
                            </div>
                    }
               
            </div>
        </div>
    )
}

export default MunicipalitiesPage