
import DashboardHeader from '../components/DashboardHeader';
import {PopupboxContainer} from 'react-popupbox';

const Page = (props) => (
    <div className='dashboard-content'>
        <DashboardHeader btnText={props.buttonText} link={props.buttonUrl} />
        <div className='dashboard-content-container'>
            <div className='dashboard-content-header'>
                <h2>{props.header}</h2>
            </div>
            <div className="card-body">
                <PopupboxContainer/>
                {props.children}
            </div>
        </div>
    </div>
)

export default Page;