import React, {useState, useEffect} from "react"
import OrganizationApi from "../../services/organizations";
import {useParams} from "react-router-dom";

import Page from "../Page"
import OrganizationPanel from "../../panels/OrganizationPanel"
import Loading from "../../fragments/Loading"


function SponsorPage(props) {
    const [sponsor, setSponsor] = useState(null);
    const organizationApi = new OrganizationApi(props.token);
    const routeParams = useParams();
    const { organizationId, eventId, sponsorId } = routeParams;

    const fetch = () => {
        organizationApi.getById(sponsorId).then((response) => {
            setSponsor(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    }

    useEffect(() => {
        fetch()
    }, []);

    if(!sponsor) {
        return (<Loading />)
    }
    
    const handleBack =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${routeParams.organizationId}/event/${routeParams.eventId}/sponsors`
        }else{
            return `/organization/${organizationId}/event/${eventId}/sponsors`
        }
    }

    return(
        <Page 
            buttonText="Back"
            buttonUrl={handleBack()}
            header={`Sponsor: ${sponsor? sponsor.name: ""}`}
        >
            <OrganizationPanel organization={sponsor} {...props} />
        </Page>
    )
}

export default SponsorPage;