import React, { useState, useEffect } from "react"
import CountryApi from "../../services/countries";
import CountryTable from "../../tables/MunicipleHierarchy/CountryTable"
import DashboardHeader from '../../components/DashboardHeader';
import { PopupboxContainer } from 'react-popupbox';
import Pagination from "../../components/Pagination"
import LetterFilter from "../../components/LetterFilter";

function CountriesPage(props) {
    const [countries, setCountries] = useState([]);
    const countryApi = new CountryApi(props.token);
    const [search, setSearch] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [pageItemActive, setPageItemActive] = useState(1);
    const [searchId, setSearchId] = useState('');
    const [activeLetter, setActiveLetter] = useState('all'); 
  
    const fetchData = async (page, search, searchId, activeLetter) => {
        try {
            let response;
            if (searchId) {
                response = await countryApi.searchCountryById(searchId);
                setPageItemActive(1);
            } 
            else if (search) {
                response = await countryApi.search(search, page);
            } 
            else if (activeLetter && activeLetter !== 'all') {
                let formatLetter = activeLetter.toLowerCase();
                response = await countryApi.filterByLetter(formatLetter, page);
            } 
            else {
                response = await countryApi.all(page);
                setActiveLetter('all');
            }
            if (response) {
                setCountries([...response.countries]);
                setTotalPages(response.total_pages || 1);
            }
        } catch (error) {
            console.error(error.message, error.stack);
        }
    };

    useEffect(() => {
        fetchData(pageItemActive, search, searchId, activeLetter);
    }, [search, searchId, pageItemActive, activeLetter])


    const __handleSearch = (event) => {
        setSearch(event.target.value);
        setActiveLetter('')
    };

    const __handleSearchById = (event) => {
        setSearchId(event.target.value);
        setActiveLetter('')
    };

    const handleSelectPage = (pageNumber)=>{
        setPageItemActive(pageNumber);
    }

    const handleLetterClick = (letter) => {
        setSearch('')
        setSearchId('')
        setPageItemActive(1); 
        if (letter === 'all') {
            setActiveLetter('all');
        } else {
            setActiveLetter(letter);
        }
      };


    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="New Country" link='/country/new' />
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Countries</h2>
                    <div>
                        <div className='dashboard-content-search me-2'>
                            <input
                                type='text'
                                value={searchId}
                                placeholder='Search by id..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearchById(e)} />
                        </div>
                        <div className='dashboard-content-search'>
                            <input
                                type='text'
                                value={search}
                                placeholder='Search by name..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearch(e)} />
                        </div>
                    </div>
                </div>
                <LetterFilter activeLetter={activeLetter} onLetterClick={handleLetterClick}/>
                <div className="organizations-custom-popup">
                    <PopupboxContainer />
                </div>
                    {
                        countries.length > 0 ?
                        <div>
                            <CountryTable countries={countries} {...props} refresh={fetch} />
                            <Pagination totalPages={totalPages} pageItemActive={pageItemActive} handleSelectItem={handleSelectPage}/>
                         </div>
                            :
                            <div className='dashboard-content-footer'>
                                <span className='empty-table'>No data</span>
                            </div>
                    }
            </div>
        </div>
    )
}

export default CountriesPage