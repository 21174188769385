import axios from "axios";

const {REACT_APP_API_URI} = process.env;

const BaseUrl = `${REACT_APP_API_URI}/admin/dashboard`


export function getDashboard(token) {
    try{

        return axios.get(`${BaseUrl}`,{
            headers: { 
                'Authorization': 'Bearer ' + token,
            }
        })
    }catch(error) {
        return[]
    }
}
