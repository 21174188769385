import TextPanelItem from "../fragments/panel/TextPanelItem"
import BoolPanelItem from "../fragments/panel/BoolPanelItem"
import ImagePanelItem from "../fragments/panel/ImagePanelItem"
import Panel from "../fragments/panel/Panel"

const LocationPanel = (props) => (
    <Panel>
        <TextPanelItem value={props.location.id} label="ID" />
        <TextPanelItem value={props.location.name} label="Name" />
        <TextPanelItem value={props.location.address_1} label="Address 1" />
        <TextPanelItem value={props.location.address_2} label="Address 2" />
        <TextPanelItem value={props.location.city} label="City" />
        <TextPanelItem value={props.location.state} label="State" />
        <TextPanelItem value={props.location.zip} label="Zip" />
        <TextPanelItem value={props.location.country} label="Country" />
        <TextPanelItem value={`${props.location.longitude}, ${props.location.latitude}`} label="Coordinates" />
        <BoolPanelItem value={props.location.is_published}  label="Published?" />
    </Panel>
)

export default LocationPanel;