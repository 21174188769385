
import FormGroup from "./FormGroup"
import {useState, useEffect} from 'react';

const ImageSelector = (props) => {
    const onChange = (e) => {
        props.onChange(props.id, e.target.files[0]);
    }

    useEffect(() => {
        if(props.onLoad) {
            props.onLoad();
        }
    },[])

    return (
        <FormGroup {...props}>
            <input required={props.required || false} className="form-control"
                    type="file"
                    name={props.id}
                    onChange={onChange}
            />
        </FormGroup>
    )
}

export default ImageSelector;