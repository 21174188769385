
import FormGroup from "./FormGroup"
import {useState, useEffect} from 'react';

const CheckboxSelector = (props) => {
    const onChange = (e) => {
        props.onChange(props.id, e.target.checked ? 1 : 0);
    }

    useEffect(() => {
        if(props.onLoad) {
            props.onLoad();
        }
    },[])
    return (
        <FormGroup {...props}>
            <input className="form-check-input"
                type="checkbox"
                name={props.id}
                defaultChecked={props.value}
                onChange={onChange}
            />
        </FormGroup>
    )
}

export default CheckboxSelector;