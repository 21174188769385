
import {useState} from 'react';
import TextField from "../fragments/form/TextField"
import SubmitButton from "../fragments/form/SubmitButton"


function CreateEventParticipantForm(props) {
    const [formData, setFormData] = useState({});

    const onChange = (id, value) => {
        formData[id] = value;
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(formData)
    }
  
    return (
        <form onSubmit={onSubmit}>
            <TextField id="name" required={true} onChange={onChange} label="Title" onLoad={() => onChange("name", "")}  />
            <SubmitButton />
        </form>
    );
}

export default CreateEventParticipantForm;