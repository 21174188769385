import {useEffect,useState} from 'react';
import {useLocation} from "react-router-dom";
import DashboardHeader from '../../components/DashboardHeader';
import '../styles.css';
import CategoryApi from "../../services/categories";
import EditCategoryform from "./edit-form";

function SingleCategory(props) {
    const  location = useLocation();
    const { id } = location.state;
    const [data, setData] = useState('');
    const categoryApi = new CategoryApi(props.token);

    useEffect(() => {
        categoryApi.getById(id).then((response) => {
            setData(response);
        })
        .catch((err) => {
            console.error(err.message);
        });
    },[]);
    if (!data) return null;
    console.log(data);

    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="Back" link="/categories" />

            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Edit Category : {data.name}  </h2>
                </div>
                <EditCategoryform token={props.token} 
                category={data.category}
                icon={data.icon}
                />
            </div>
        </div>
    );
}

export default SingleCategory;