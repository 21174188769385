import React, { useState, useEffect } from "react"
import ParticipantApi from "../../services/participants";
import Page from "../Page"
import ParticipantsTable from "../../tables/ParticipantsTable"
import DashboardHeader from '../../components/DashboardHeader';
import { PopupboxContainer } from 'react-popupbox';
import {useParams} from "react-router-dom";

function ParticipantsPage(props) {
    const [participants, setParticipants] = useState([]);
    const participantApi = new ParticipantApi(props.token);
    const [search, setSearch] = useState('');
    const routeParams = useParams();
    const { organizationId, eventId } = routeParams;

    const fetch = (eventId) => {
        participantApi.getParticipants(eventId).then((result) => {
            setParticipants([...result])
        })
    }

    useEffect(() => {
        fetch(eventId)
    }, []);

    // Search
    const __handleSearch = (event) => {
        setSearch(event.target.value);
        if (event.target.value !== '') {
            let search_results = participants.filter((item) => item.participant_name.toLowerCase().includes(event.target.value.toLowerCase())
            );
            setParticipants([...search_results]);
        }
        else {
            fetch(eventId)
        }
    };

    const handleOpenCreateParticipantPage =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}/event/${eventId}/participant/new`
        }else{
            return `/organization/${organizationId}/event/${eventId}/participant/new`
        }
    }

    return (
        <div className='dashboard-content'>
            <DashboardHeader btnText="New Participant" link={handleOpenCreateParticipantPage()} />
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Participants</h2>
                    <div className='dashboard-content-search'>
                        <input
                            type='text'
                            value={search}
                            placeholder='Search..'
                            className='dashboard-content-input'
                            onChange={e => __handleSearch(e)} />
                    </div>
                </div>
                <div className="organizations-custom-popup">
                    <PopupboxContainer />
                </div>
                    {
                        participants.length> 0 ?
                            <ParticipantsTable participants={participants} {...props} refresh={(eventId)=>fetch(eventId)} eventId={eventId} organizationId={organizationId}/>
                            :
                            <div className='dashboard-content-footer'>
                                <span className='empty-table'>No data</span>
                            </div> 
                    }
            </div>
        </div>
    )
}

export default ParticipantsPage