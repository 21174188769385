import React, {useState, useEffect} from "react"
import OrganizationApi from "../../services/organizations";
import ParticipantApi from "../../services/participants";
import {useParams} from "react-router-dom";

import Page from "../Page"
import ParticipantPanel from "../../panels/ParticipantPanel"
import Loading from "../../fragments/Loading"


function ParticipantPage(props) {
    const [participant, setParticipant] = useState(null);
    const organizationApi = new OrganizationApi(props.token);
    const participantApi  = new ParticipantApi(props.token)
    const routeParams = useParams();
    const { organizationId, eventId, participantId } = routeParams;

    const fetch = () => {
        try {
            organizationApi.getById(organizationId).then((organizationData)=>{
                let data = {}
                data = { ...data, ...organizationData };
                data.id = parseInt(participantId)
                setParticipant({...data})
                participantApi.getRoleById(participantId).then((participantRoleData)=>{
                    data ={...data,participant_role_id: participantRoleData.id,  participant_role_name: participantRoleData.name}
                    setParticipant({...data})
                }).catch((err) => {
                    console.error(err.message);
                });
            }) .catch((err) => {
                console.error(err.message);
            });
        } catch (err) {
            console.error(err.message, err.stack);
        }
    }

    useEffect(() => {
        fetch()
    }, []);

    const handleBack =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}/event/${eventId}/participants`
        }else{
            return `/organization/${organizationId}/event/${eventId}/participants`
        }
    }

    if(!participant) {
        return (<Loading />)
    }
    
    return(
        <Page 
            buttonText="Back"
            buttonUrl={handleBack()}
            header={`Participant: ${participant? participant.name: ""}`}
        >
            <ParticipantPanel participant={participant} {...props} />
        </Page>
    )
}

export default ParticipantPage;