
import {useState} from 'react';

import TextField from "../fragments/form/TextField"
import TextAreaField from "../fragments/form/TextAreaField"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import ImageSelector from "../fragments/form/ImageSelector"
import DropdownSelector from "../fragments/form/DropdownSelector"
import SubmitButton from "../fragments/form/SubmitButton"
import SearchSelector from '../components/SearchSelector/RegionSelect';

function CreateOrganizationForm(props) {
    const [formData, setFormData] = useState(new FormData());
    const [isSubmitted, setIsSubmitted] = useState(false);

    const onChange = (id, value) => {
        formData.set(id, value);
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        props.onSubmit(formData, props.location)
    }

  const updateLocationFields = (id, name, value) => {
    let newLocation = {...props.location}
    if (id === 'country') {
      newLocation.country_id = value;
      newLocation.country = name;
    }
    if (id === 'state') {
      newLocation.state_id = value;
      newLocation.state = name;
    }
    if (id === 'city') {
      newLocation.city_id = value;
      newLocation.city = name;
    }
    props.handleSetLocation(newLocation);
  }

  const resetLocationFields = (id) => {
    if (!props.location) {
      return;
    }

    let newLocation = { ...props.location };
    if (id === 'country') {
      newLocation.country = '';
      newLocation.country_id = null;
      newLocation.state = '';
      newLocation.state_id = null;
      newLocation.city = '';
      newLocation.city_id = null;
    } else if (id === 'state') {
      newLocation.state = '';
      newLocation.state_id = null;
      newLocation.city = '';
      newLocation.city_id = null;
    } else if (id === 'city') {
      newLocation.city = '';
      newLocation.city_id = null;
    }
    props.handleSetLocation(newLocation);
  };

    const onChangeLocation =(id,value)=>{
        let newLocation = {...props.location, [id]: value}
        props.handleSetLocation(newLocation);
    }

    return (
        <form onSubmit={onSubmit}>
            <DropdownSelector id="category_id" required={true} collection={props.categories} onChange={onChange} label="Category" prompt="Please select a category"  onLoad={() => onChange("category_id", 0)} />
            <TextField id="name" required={true} onChange={onChange} label="Title" onLoad={() => onChange("name", "")}  />
            <TextAreaField id="description" required={true} onChange={onChange} label="Description" rows="10" cols="20" onLoad={() => onChange("description", "")} />
            <TextField id="website_url" required={false} onChange={onChange} label="Website" onLoad={() => onChange("website_url", "")}  />
            <TextField id="twitter_name" required={false} onChange={onChange} label="Twitter Name" onLoad={() => onChange("twitter_name", "")}  />
            <TextField id="facebook_name" required={false} onChange={onChange} label="Facebook Name" onLoad={() => onChange("facebook_name", "")}  />
            <TextField id="instagram_name" required={false} onChange={onChange} label="Instagram Name" onLoad={() => onChange("instagram_name", "")}  />
            <TextField id="linkedin_name" required={false} onChange={onChange} label="LinkedIn Name" onLoad={() => onChange("linkedin_name", "")}  />
            <TextField id="email_address" required={false} onChange={onChange} label="Email Address" onLoad={() => onChange("email_address", "")}  />
            <TextField id="phone_number" required={false} onChange={onChange} label="Phone Number" onLoad={() => onChange("phone_number", "")}  />
            <TextField id="address_1" required={true} onChange={onChangeLocation} label="Address 1" onLoad={() => onChangeLocation("address_1","")}  />
            <TextField id="address_2" required={true} onChange={onChangeLocation} label="Address 2" onLoad={() => onChangeLocation("address_2","")}  />
            <SearchSelector
              id="country"
              required={true}
              isSubmitted={isSubmitted}
              location={props.location}
              collection={props.countries}
              updateLocationFields={updateLocationFields}
              handleSearchLocation={props.handleSearchLocation}
              resetLocationFields={resetLocationFields}
              label="Country"
              prompt="Please search and select a country" />
            <SearchSelector
              id="state"
              required={true}
              isSubmitted={isSubmitted}
              location={props.location}
              collection={props.states}
              updateLocationFields={updateLocationFields}
              handleSearchLocation={props.handleSearchLocation}
              resetLocationFields={resetLocationFields}
              label="State"
              prompt="Please select a country before select a state"
            />
            <SearchSelector
              id="city"
              required={true}
              isSubmitted={isSubmitted}
              location={props.location}
              collection={props.cities}
              updateLocationFields={updateLocationFields}
              handleSearchLocation={props.handleSearchLocation}
              resetLocationFields={resetLocationFields}
              label="City"
              prompt="Please select a state before select a city"
            />            
            <TextField id="zip" required={true} onChange={onChangeLocation} label="Zip" onLoad={() => onChangeLocation("zip", "")}  />
            <TextField id="longitude" type="number" required={true} onChange={onChangeLocation} label="Longtitude" onLoad={() => onChangeLocation("longitude", "")}  />
            <TextField id="latitude" type="number" required={true} onChange={onChangeLocation} label="Latitude" onLoad={() => onChangeLocation("latitude","")}  />
            <ImageSelector id="card_image" required={true} label="Card Image" onChange={onChange} onLoad={() => onChange("card_image", null)}  />
            <ImageSelector id="profile_image" required={true} label="Profile Image" onChange={onChange} onLoad={() => onChange("profile_image", null)}  />
            <CheckboxSelector id="is_published" required={true} onChange={onChange} label="Publish" onLoad={() => onChange("is_published", "0")} />
            <SubmitButton />
        </form>
    );
}

export default CreateOrganizationForm;