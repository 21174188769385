import axios from "axios";


class API {
    constructor(token) {
        this.token = token;
    }

    get(url) {
        let headers = {
            'Authorization': 'Bearer ' + this.token,
        }
        return axios.get(
            url, {
                headers: headers
            }
        )
    }

    patch(url, body) {
        let headers = {
            'Authorization': 'Bearer ' + this.token,
        }
        return axios.patch(url, body, {
            headers: headers
        })
    }

    post(url, body, contentType) {
        let headers = {
            'Authorization': 'Bearer ' + this.token,
        }
        if(contentType) {
            headers["Content-Type"] = contentType
        }
        return axios.post(url, body, {
            headers: headers
        })
    }

    delete(url) {
        let headers = {
            'Authorization': 'Bearer ' + this.token,
        }
        return axios.delete(
            url, {
                headers: headers
            }
        )
    }
}

export default API;