import React, {useState} from 'react';
import ParticipantRoleApi from "../services/participantRole";
import Error from "../fragments/form/Error"
import Message from "../fragments/form/Message"

import TextValue from "../fragments/table/TextValue"
import {Link} from "react-router-dom";
import ConfirmDeletePopup from "../fragments/popup/ConfirmDeletePopup";


function ParticipantRolesTable (props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const participantRoleApi = new ParticipantRoleApi(props.token);
    let { organizationId, eventId , roles} = props;

    function deleteParticipantRole(id, name){
        participantRoleApi.delete(id).then((response) => {
            setMessage(`Participant Role: "${name}" has been deleted`)
            setError("");
            props.refresh();
        })
        .catch((err) => {
            console.error(err.message);
            setError(err.message);
            setMessage("");
        });
    }
    
    return(
        <div>
            <Error message={error} />
            <Message message={message} />
            <table>
                <tbody>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>ACTIONS</th>
                    </tr>
                    {roles.map((role, index) => {
                        return (
                            <tr key={index}>
                                <td><TextValue value={role.id} /></td>
                                <td><TextValue value={role.name} /></td>
                                <td>
                                    <span>
                                        <Link to={`/organization/${organizationId}/event/${eventId}/participant/role/${role.id}`}>
                                            <i className="bi">View</i>
                                        </Link>
                                        <br />
                                        <ConfirmDeletePopup
                                            onConfirm={() => deleteParticipantRole(role.id, role.name)}
                                            prompt={`Are you sure to delete ${role.name} ?`}
                                            acceptLabel="Delete"
                                        />
                                    </span>
                                </td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default ParticipantRolesTable;