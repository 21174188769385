import React, {useEffect, useState, useRef,useMemo} from "react"
import {updateBlogpost} from "../../services/blogposts";


function Blogform(props){
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(props.blog.is_published);


    const [response, setResponse] = useState({
        "title": props.blog.title,
        "body": props.blog.body,
        "is_published": props.blog.is_published,
    })
    const handleOnChange = e => {
        if (e.target.checked) {
            setIsSubscribed(current => !current);
        } else {
            setIsSubscribed(current => !current);
        }

        setResponse({...response, [e.target.name]: e.target.value})
    }

    function updateBlog(id,body){
        updateBlogpost(id,body,props.token).then((response) => {
            setData(response.data);
        })
            .catch((err) => {
                console.log(err.message);
                setError(err.message)
            });
    }

    const handleOnSubmit = e => {
        e.preventDefault();
        updateBlog(props.blog.id,{
            "title": response.title,
            "body": response.body,
            "is_published": isSubscribed,
        })
    }


    return(
        <div className="card-body">
            {error ? <div className="alert alert-danger" role="alert">
                {error}
            </div>: null }
            {data ? <div className="alert alert-success" role="alert">
              Blog post with ID  {data.data.blogpost.id} has been edited
            </div>: null }


        <form onSubmit={handleOnSubmit}>
            <div className="col-md-4 mb-3">
            <label htmlFor="title">Title</label>
            <div className="form-group">
                <input required className="form-control"
                    type="text"
                    name="title"
                       defaultValue={response.title}
                       onChange={handleOnChange}
                />
            </div>
            </div>
            <div className="col-md-4 mb-3">
                <label htmlFor="body">Body</label>
                <div className="form-group">
                    <input required className="form-control"
                           type="text"
                           name="body"
                           defaultValue={response.body}
                           onChange={handleOnChange}
                    />
                </div>
            </div>
            <div className="col-md-4 mb-3">
                <label htmlFor="is_published">Publish</label>
                <div className="form-group">
                    <input className="form-check-input"
                           type="checkbox"
                           name="is_published"
                           defaultChecked={isSubscribed}
                           onChange={handleOnChange}
                    />
                </div>
            </div>
            <div className="col-md-4 mb-3">

                <div className="form-group">
                    <button type="submit" className="btn btn-primary" >Submit</button>
                </div>
            </div>
        </form>

        </div>
    )
}

export default Blogform;