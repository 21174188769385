
import TextField from "./TextField"

const FloatField = (props) => {
    const onChange = (id, value) => {
        props.onChange(id, parseFloat(value, 10));
    }

    return (
        <TextField {...props} onChange={onChange} />
    )
}

export default FloatField;