import HeaderNav from './HeaderNav'
import HeaderNavItem from './HeaderNavItem'

const OrganizationLocationNavHeader = (props) => {
    const {countryId, stateId, municipalityId, organizationId} = props;
    return (
    <HeaderNav>
        <HeaderNavItem location={`/country/${countryId}/state/${stateId}/municipality/${municipalityId}/organization/${organizationId}`} label="Overview" />
        <HeaderNavItem location={`/country/${countryId}/state/${stateId}/municipality/${municipalityId}/organization/${organizationId}/edit`} label="Edit" />
        <HeaderNavItem location={`/country/${countryId}/state/${stateId}/municipality/${municipalityId}/organization/${organizationId}/locations`} label="Locations" />
        <HeaderNavItem location={`/country/${countryId}/state/${stateId}/municipality/${municipalityId}/organization/${organizationId}/events`} label="Events" />
        {
            props.isShowArchive  &&  <HeaderNavItem location={`/country/${countryId}/state/${stateId}/municipality/${municipalityId}/organization/${organizationId}/archive`} label="Archive" />
        }
    </HeaderNav>
        )
    }

export default OrganizationLocationNavHeader;