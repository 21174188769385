import API from './api'

const {REACT_APP_API_URI} = process.env;


class CountryApi {
    constructor(token) {
        this.api = new API(token);
        this.baseUrl = `${REACT_APP_API_URI}/admin/country`
    }

    all(pageNumber) {
        try {
            return this.api.get(`${this.baseUrl}?page_num=${pageNumber}`)
            .then((response) => {
                return {
                    countries: Object.values(response.data.data.countries),
                    total_pages: response.data.data.total_pages
                }
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    search(country, pageNumber) {
        try {
            return this.api.get(`${this.baseUrl}?country=${country}&page_num=${pageNumber}`)
            .then((response) => {
                return {
                    countries: Object.values(response.data.data.countries),
                    total_pages: response.data.data.total_pages
                }
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    getById(id) {
        try {
            return this.api.get(`${this.baseUrl}/${id}`)
            .then((response) => {
                return response
            });
        } catch(error) {
            console.error(`Error fetching country. id=${id}, error=${error}`);
            return {}
        }
    }

    update(id, body) {
        try{
            return this.api.patch(`${this.baseUrl}/${id}`, body, "multipart/form-data")
            .then((response) => {
                return response;
            });
        }catch(error) {
            console.error(`Error updating country. id=${id}, error=${error}`);
            return {}
        }
    }

    create(body) {
        try{
            return this.api.post(this.baseUrl, body, "multipart/form-data")
            .then((response) => {
                return response;
            });
        }catch(error) {
            console.error(`Error creating country. error=${error}`);
            return {}
        }
    }

    getlist(maxCountry){
        try {
            return this.api.get(`${this.baseUrl}?max_country=${maxCountry}`)
            .then((response) => {
                return Object.values(response.data.data.countries)
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    getCountriesByName(country) {
        try {
            return this.api.get(`${this.baseUrl}?country=${country}`)
                .then((response) => {
                    return Object.values(response.data.data.countries)
                });
        } catch (error) {
            console.error(error);
            return []
        }
    }

    searchCountryById(id){
        try {
            return this.api.get(`${this.baseUrl}/${id}`)
            .then((response) => {
                return{
                        countries:  Object.values(response.data.data),
                        total_pages: response.data.data.total_pages
                    } 
            });
        } catch(error) {
            console.error(`Error fetching country. id=${id}, error=${error}`);
            return {}
        } 
    }

    filterByLetter(value,pageNumber){
        try {
            let letter = value.toLowerCase()
            return this.api.get(`${this.baseUrl}?&country_start=${letter}&page_num=${pageNumber}`)
                .then((response) => {
                    return {
                        countries: Object.values(response.data.data.countries),
                        total_pages: response.data.data.total_pages
                    }
                });
        } catch (error) {
            console.error(error);
            return []
        }
    }

    getCountryListByLetter(value){
        try {
            let letter = value.toLowerCase()
            return this.api.get(`${this.baseUrl}?&country_start=${letter}`)
                .then((response) => {
                    return {
                        countries: Object.values(response.data.data.countries),
                        total_pages: response.data.data.total_pages
                    }
                });
        } catch (error) {
            console.error(error);
            return []
        }
    }

}

export default CountryApi;