
import FormGroup from "./FormGroup"

const TextLabel = (props) => (
    <FormGroup {...props}>
        {props.value}
    </FormGroup>
)


export default TextLabel;