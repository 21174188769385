import React, { useState, useEffect } from "react"
import StateApi from "../../services/states";
import CountryApi from "../../services/countries";
import StateTable from "../../tables/MunicipleHierarchy/StateTable"
import DashboardHeader from '../../components/DashboardHeader';
import { PopupboxContainer } from 'react-popupbox';
import Pagination from "../../components/Pagination"
import LetterFilter from "../../components/LetterFilter";
import { useParams } from "react-router-dom";

function StatesPage(props) {
    const [states, setStates] = useState([]);
    const [countryName, setCountryName] = useState('')
    const [search, setSearch] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [pageItemActive, setPageItemActive] = useState(1);
    const [searchId, setSearchId] = useState('');
    const [activeLetter, setActiveLetter] = useState('all'); 

    const stateApi = new StateApi(props.token);
    const countryApi = new CountryApi(props.token)
    const {countryId} = useParams()

    const getStateList = async (page, search, searchId, activeLetter) => {
        try {
            let response;
            if (searchId) {
                response = await stateApi.searchStateById(searchId);
                setPageItemActive(1);
            } 
            else if (search) {
                response = await stateApi.search(countryId,search,pageItemActive)
            } 
            else if (activeLetter && activeLetter !== 'all') {
                let formatLetter = activeLetter.toLowerCase();
                response = await stateApi.filterByLetter(countryId, formatLetter, page);
            } 
            else {
                response = await stateApi.all(countryId, page);
                setActiveLetter('all');
            }
            if (response) {
                setStates([...response.states]);
                setTotalPages(response.total_pages || 1);
            }
        } catch (error) {
            console.error(error.message, error.stack);
        }
    };

    const getCountry = async(countryId)=>{
        try {
           let response = await countryApi.getById(countryId);
           setCountryName(response.data.data.country.country)
        } catch (error) {
            console.error(error.message, error.stack);
        }
    }

    const fetchData =async(countryId, search, searchId, pageItemActive,activeLetter)=>{
        await getCountry(countryId)
        await getStateList(pageItemActive, search, searchId, activeLetter);
    }

    useEffect(()=>{
        fetchData(countryId, search, searchId, pageItemActive,activeLetter)
    },[ countryId, search, searchId, pageItemActive,activeLetter])

    const __handleSearch = (event) => {
        setSearch(event.target.value);
        setActiveLetter('')
    };

    const __handleSearchById = (event) => {
        setSearchId(event.target.value);
        setActiveLetter('')
    };

    const handleSelectPage = (pageNumber)=>{
        setPageItemActive(pageNumber);
    }

    const handleLetterClick = (letter) => {
        setSearch('')
        setSearchId('')
        setPageItemActive(1); 
        if(letter === 'all'){
            setActiveLetter('all'); 
        }else{
            setActiveLetter(letter); 
        }
      };

    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="New Province/State" link={`/country/${countryId}/state/new`} />
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    {countryName ? <h2 className="municipality-title">{countryName}</h2> : <h2>Province/State</h2> }
                    <div>
                        <div className='dashboard-content-search me-2'>
                            <input
                                type='text'
                                value={searchId}
                                placeholder='Search by id..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearchById(e)} />
                        </div>
                        <div className='dashboard-content-search'>
                            <input
                                type='text'
                                value={search}
                                placeholder='Search by name..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearch(e)} />
                        </div>
                    </div>
                </div>
                <LetterFilter activeLetter={activeLetter} onLetterClick={handleLetterClick}/>
                <div className="organizations-custom-popup">
                    <PopupboxContainer />
                </div>
                    {
                        states.length > 0 ?
                        <div>
                            <StateTable states={states} {...props} refresh={fetch} />
                            <Pagination totalPages={totalPages} pageItemActive={pageItemActive} handleSelectItem={handleSelectPage}/>
                         </div>
                            :
                            <div className='dashboard-content-footer'>
                                <span className='empty-table'>No data</span>
                            </div>
                    }
            </div>
        </div>
    )
}

export default StatesPage