import API from './api'

const {REACT_APP_API_URI} = process.env;


class ParticipantRoleApi {
    constructor(token) {
        this.api = new API(token);
        this.baseUrl = `${REACT_APP_API_URI}`
    }
   
    getRoles() {
        try {
            return this.api.get(`${this.baseUrl}/admin/participation-role`)
            .then((response) => {
                return response.data.data.participation_roles;
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    getById(id) {
        try {
            return this.api.get(`${this.baseUrl}/admin/participation-role/${id}`)
            .then((response) => {
                return response.data.data.participation_role
            });
        } catch(error) {
            console.error(`Error fetching participation role. id=${id}, error=${error}`);
            return {}
        }
    }

    create(body) {
        try{
            return this.api.post(`${this.baseUrl}/admin/participation-role`, body, "application/json")
            .then((response) => {
                return response.data.data.participation_roles
            });
        }catch(error) {
            console.error(`Error creating participation role. error=${error}`);
            return {}
        }
    }

    delete(id) {
        try{
            return this.api.delete(`${this.baseUrl}/admin/participation-role/${id}`)
            .then((response) => {
                return response.data.data;
            });
        }catch(error) {
            console.error(`Error deleting participation role. error=${error}`);
            return {}
        }
    }
}

export default ParticipantRoleApi;