import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import CountryApi from "../../services/countries";

import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"
import Loading from "../../fragments/Loading";
import EditCountryForm from "../../forms/MunicipleHierarchy/EditCountryForm";
import Page from "../Page";

const EditCountryPage = (props) => {
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [country, setCountry] = useState();

    const countryApi = new CountryApi(props.token);
    const { countryId } = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        countryApi.getById(countryId).then((response) => {
          setCountry(response.data.data.country)
        })
    }, [countryId]);

    const onSubmit = (formData) => {
        countryApi.update(countryId, formData).then((response) => {
            navigate(`/countries`);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        });
    }

    if(!country) {
        return (<Loading />)
    }
    return (
        <Page
            buttonText="Back"
            buttonUrl={`/countries`}
            header={`Edit Country: ${country.country}`}
        >
            <Error message={error} />
            <Message message={message} />
            <EditCountryForm 
                {...props}
                country={country}
                onSubmit={onSubmit} />
        </Page>

    );
}

export default EditCountryPage;
