import React, {useState, useEffect} from "react"
import {useParams} from "react-router-dom";

import OrganizationApi from "../../services/organizations";
import ArchiveApi from "../../services/archivedEvents";

import EventsTable from "../../tables/EventsTable"
import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav"
import OrganizationLocationHeaderNav from "../../fragments/nav/OrganizationLocationHeaderNav"

import Page from "../Page"
import Loading from "../../fragments/Loading";
import Pagination from '../../components/Pagination';


function ArchivedEventsPage(props) {
    const [archiveList, setArchiveList] = useState();
    const [organization, setOrganization] = useState();
    const [totalPages, setTotalPages] = useState(0);
    const [pageItemActive, setPageItemActive] = useState(1);

    const archiveApi = new ArchiveApi(props.token);
    const organizationApi = new OrganizationApi(props.token);
    const routeParams = useParams();
    const { organizationId } = routeParams;

    const fetch = () => {
        archiveApi.getArchiveList(organizationId, pageItemActive).then((response) => {
            setArchiveList(response.events);
            setTotalPages(response.total_pages)
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    }

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    },[]);

    useEffect(() => {
        fetch();
    },[organizationId, pageItemActive]);

    if(!archiveList || !organization) {
        return (<Loading />)
    }

    const handleSelectPage =  (pageNumber )=>{
        setPageItemActive(pageNumber);
    }

    const handleOpenCreateEventPage =()=>{
        if(routeParams.countryId && routeParams.stateId && routeParams.municipalityId && organizationId){
            return `/country/${routeParams.countryId}/state/${routeParams.stateId}/municipality/${routeParams.municipalityId}/organization/${organizationId}/event/new`
        }else{
            return `/organization/${organizationId}/event/new`
        }
    }

    return(
        <Page 
            buttonText="Create Event"
            buttonUrl={handleOpenCreateEventPage()}
            header={`Archived events for the organization: ${organization.name}`}
        >
            {routeParams.countryId && routeParams.stateId && routeParams.municipalityId && routeParams.organizationId ?
                <OrganizationLocationHeaderNav countryId={routeParams.countryId} stateId={routeParams.stateId} municipalityId={routeParams.municipalityId} organizationId={routeParams.organizationId} isShowArchive={true} />
                :
                <OrganizationHeaderNav organizationId={organizationId} isShowArchive={true} />
            }
            {
               archiveList && archiveList.length > 0 ? <EventsTable
                    events={archiveList}
                    organization={organization}
                    refresh={fetch}
                    {...props} />
                    :
                    <div className='dashboard-content-footer'>
                        <span className='empty-table'>No data</span>
                    </div>
            }
            <Pagination totalPages={totalPages} pageItemActive={pageItemActive} handleSelectItem={handleSelectPage}/>
        </Page>
    )
}

export default ArchivedEventsPage;