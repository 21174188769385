import React, {useState} from "react"
import {createSlide} from "../../services/slides";


function CreateSlideform(props){
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);


    const [response, setResponse] = useState({
        "title": "",
        "head_title": "",
        "subtitle": "",
        "button1_content": "",
        "button2_content": "",
        "button1_url": "",
        "button2_url": "",
        "image_url": "",
    })
    const handleOnChange = e => {

        setResponse({...response, [e.target.name]: e.target.value})
    }

    function createSingleSlide(body){
        createSlide(body,props.token).then((response) => {
            setData(response.data);
        })
            .catch((err) => {
                console.log(err.message);
                setError(err.message)
            });
    }

    const handleOnSubmit = e => {
        e.preventDefault();
        createSingleSlide({
            "title": response.title,
            "head_title": response.head_title,
            "subtitle": response.subtitle,
            "button1_content": response.button1_content,
            "button2_content": response.button2_content,
            "button1_url": response.button1_url,
            "button2_url": response.button2_url,
            "image_url": response.image_url
        })
    }


    return(
        <div className="card-body">
            {error ? <div className="alert alert-danger" role="alert">
                {error}
            </div>: null }
            {data ? <div className="alert alert-success" role="alert">
                Slide with ID {data.data.slide.id} has been created
            </div>: null }


            <form onSubmit={handleOnSubmit}>
                <div className="col-md-4 mb-3">
                    <label htmlFor="head_title"> Header Title</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="head_title"
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label htmlFor="title">Title</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="title"
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label htmlFor="subtitle">Subtitle</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="subtitle"
                               onChange={handleOnChange}
                        />
                    </div>
                </div>

                <div className="col-md-4 mb-3">
                    <label htmlFor="button1_content">First Button Content</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="button1_content"
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label htmlFor="button1_url">First Button Url</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="button1_url"
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label htmlFor="button2_content">Second Button Content</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="button2_content"
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label htmlFor="button2_url">Second Button Url</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="button2_url"
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <label htmlFor="image_url">Image Url</label>
                    <div className="form-group">
                        <input required className="form-control"
                               type="text"
                               name="image_url"
                               onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary" >Submit</button>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default CreateSlideform;