import React, {useState, useEffect} from 'react';
import DashboardHeader from '../../components/DashboardHeader';

import {calculateRange, sliceData} from '../../utils/table-pagination';

import '../styles.css';
import {getSlides} from "../../services/slides";
import SlideTables from "./datatables";

function Slides (props) {
    const [search, setSearch] = useState('');
    const [slide, setSlide] = useState('');
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);

    useEffect(() => {
        getSlides().then((response) => {
            setSlide(sliceData(response.data.data.slide, page, 10));
        })
            .catch((err) => {
                console.log(err.message);
            });
        setPagination(calculateRange(slide, 10));
    }, []);

    // Search
    const __handleSearch = (event) => {

        setSearch(event.target.value);
        if (event.target.value !== '') {
            let search_results = slide.filter((item) =>
                item.name.toLowerCase().includes(search.toLowerCase())
            );
            setSlide(search_results);
        }
        else {
            __handleChangePage(1);
        }
    };

    // Change Page 
    const __handleChangePage = (new_page) => {
        setPage(new_page);
        setSlide(sliceData(slide, new_page, 10));
    }

    return(
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="New Slide" link='/newslide' />

            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Slides List</h2>
                    <div className='dashboard-content-search'>
                        <input
                            type='text'
                            value={search}
                            placeholder='Search..'
                            className='dashboard-content-input'
                            onChange={e => __handleSearch(e)} />
                    </div>
                </div>

               <SlideTables token={props.token} slide={slide}/>

                {slide.length !== 0 ?
                    <div className='dashboard-content-footer'>
                        {pagination.map((item, index) => (
                            <span 
                                key={index} 
                                className={item === page ? 'active-pagination' : 'pagination'}
                                onClick={() => __handleChangePage(item)}>
                                    {item}
                            </span>
                        ))}
                    </div>
                : 
                    <div className='dashboard-content-footer'>
                        <span className='empty-table'>No data</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default Slides;